import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export const ColorTextBox = (props) => {
  const { value, onHandleChange = () => false } = props;

  return (
    <Box
      sx={{
        width: 600,
        maxWidth: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        type="color"
        value={value}
        style={{
          height: "30px",
          width: "30px",
          border: "none",
          borderRadius: "3px",
          padding: "2px",
          marginRight: "4px",
        }}
        onChange={(e) => onHandleChange(e.target.value)}
      />
      <TextField
        value={value}
        fullWidth
        size="small"
        id="fullWidth"
        autoComplete="off"
        // inputProps={{ readOnly: true }}
        onChange={(e) => onHandleChange(e.target.value)}
      />
    </Box>
  );
};
