import {
  Grid,
  TextField,
  Typography,
  Button,
  Modal,
  Box,
  Tooltip,
  // Autocomplete,
} from "@mui/material";
import React from "react";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ErrorIcon from "@mui/icons-material/Error";
import { CreateRule } from "td-rule-builder";
import { performData } from "../../utils";
import { ColorTextBox, FileUpload, IsRequired } from "../../components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    // borderRadius: "8px",
    background: theme.palette.background.tableHeader,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 24px 16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    padding: "16px",
    height: "calc(100% - 64px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  paper: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  textArea: {
    width: "100%",
    // borderColor: theme.palette.border.textField,
    marginTop: "8px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    resize: "vertical",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    background: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

export const SenarioDetails = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onChangeState = (key, value) => {
    // setState({ ...states, [key]: value })
    props.updateScenarios(key, value);
  };

  const [inputSchemaList, setState] = React.useState([]);

  // const [colorstate, setColorState] = React.useState({
  // textcolor: "",
  // bgcolor: ""
  // });
  React.useEffect(() => {
    if (props?.isEdit) {
      setState(JSON.parse(props?.createQueue?.inputschema[0]?.inputschema));
    }
    // console.log(props?.isconfig);
  }, [props?.isEdit, props?.createQueue]);

  // console.log(inputSchemaList);
  // const handleChange = (key, value) => {
  // setColorState({ ...colorstate, [key]: value });
  // };
  const onSaveClicked = (data) => {
    console.log(data);
    if (!data) {
      setOpen(false);
    } else {
      let ruleid = [data?.Result[0]?.properties?.doc?._id];
      // console.log(ruleid)
      updateSenarios(props?.state?._key, ruleid);
      props.updateScenarios("scenariocriteria", ruleid);
      setOpen(false);
    }
    // console.log(data);
    // let ruleid = [data?.Result[0]?.properties?.doc?._id];
    // props.updateScenarios("scenariocriteria", [ruleid])
    // console.log(ruleid);
  };
  const updateSenarios = async (key, ruleid) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuescenarios",
          filter: {
            _key: key,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            scenariocriteria: ruleid,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          console.log(res.data.result);
          // props.tabswitchClose()
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Grid container direction="row">
        <Grid item xs={12} sx={{ p: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginRight: "16px",
            }}
          >
            <Typography variant="body1">Entry Criteria</Typography>
            <Button
              id={"Rule Config Button"}
              disabled={props?.isNew ?? false}
              variant="contained"
              style={{ backgroundColor: "#66AFFF", margin: "0 16px" }}
              onClick={() => handleOpen()}
            >
              {"Configure"}
            </Button>
            {props?.state?.scenariocriteria?.length > 0 ? (
              <Tooltip title="Scenario Criteria Completed">
                <CheckCircleIcon color="success" />
              </Tooltip>
            ) : (
              <Tooltip title="Scenario Criteria Not Completed">
                <ErrorIcon sx={{ color: "#ffcc00" }} />
              </Tooltip>
            )}
            {/* <ErrorIcon sx={{ Color: "red" }}></ErrorIcon> */}
          </div>
        </Grid>

        <Grid item lg={6} md={6} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2">
            {"Scenario Name"} <IsRequired />
          </Typography>
          <TextField
            margin="dense"
            value={props?.state?.scenarioname}
            onChange={(e) => onChangeState("scenarioname", e.target.value)}
            placeholder="Enter scenario Name"
            variant="outlined"
            autoComplete="off"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item tem lg={6} md={6} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2"> Scenario Id</Typography>
          <TextField
            margin="dense"
            variant="outlined"
            fullWidth
            size="small"
            value={props?.state?.scenarioid}
            disabled
            // placeholder="Enter Scenario ID"
            onChange={(e) => onChangeState("scenarioid", e.target.value)}
          />
        </Grid>
        {/* <Grid item lg={4} md={4} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2">{"Role"}</Typography>
          <Autocomplete
            id="choosetype"
            options={JSON.parse(sessionStorage.getItem("userGroup")) ?? []}
            getOptionLabel={(option) => option.name}
            value={props?.state?.idmrole ?? []}
            fullWidth
            name="idmrole"
            // filterSelectedOptions
            multiple
            size="small"
            onChange={(e, value) => onChangeState("idmrole", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                fullWidth
                size="small"
                margin="dense"
                name="idmrole"
              />
            )}
          />
        </Grid> */}
        <Grid item tem lg={4} md={4} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2" gutterBottom>
            {"Background Color"}
          </Typography>
          <ColorTextBox
            value={props?.state?.bgcolor}
            onHandleChange={(data) => onChangeState("bgcolor", data)}
          />
        </Grid>

        <Grid item tem lg={4} md={4} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2" gutterBottom>
            {"Text Color"}
          </Typography>
          <ColorTextBox
            value={props?.state?.textcolor}
            onHandleChange={(data) => onChangeState("textcolor", data)}
          />
        </Grid>
        <Grid item tem lg={4} md={4} xs={12} sx={{ p: 1 }}>
          <Typography variant="body2" gutterBottom>
            Scenario Icon
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FileUpload changeState={onChangeState} label={"icon"} />
            {props?.state?.icon && (
              <img
                width="20"
                height="20"
                style={{ marginLeft: "8px", marginTop: "2px" }}
                src={props?.state?.icon}
                alt={"Scenario Icon"}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} sx={{ p: 1 }}>
          <Typography variant="body2"> Scenario Description </Typography>
          <TextareaAutosize
            aria-label="minimum height"
            placeholder="Type Here"
            autoComplete="off"
            className={classes.textArea}
            minRows={5}
            value={props?.state?.scenariodescription}
            onChange={(e) =>
              onChangeState("scenariodescription", e.target.value)
            }
          />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          {/* <Box className={classes.Header}>
          <Typography variant='body1'>
            Queue Setting
          </Typography>



        </Box> */}
          <CreateRule
            inputSchema={inputSchemaList}
            // inputSchema={[props?.createQueue?.inputschema[0]?.inputschema]}
            handleClose={(data) => {
              onSaveClicked(data);
            }}
            editId={props?.state?.scenariocriteria[0] ?? ""}
            open={true}
            performvalueData={{
              // stateList: stateList,
              scenarioList: props?.scenariosList,
              // scenarioList: [props?.state],
              queueList: JSON.parse(sessionStorage.getItem("queueList")),
              userRole: JSON.parse(sessionStorage.getItem("userAssignee")),
              userGroup: JSON.parse(sessionStorage.getItem("userGroup")),
              // queueList:{[ props?.createQueue]},
              // queueList: queueList
            }}
            performKeyData={performData}
            dbName={`${process.env.REACT_APP_DB}`}
          />
        </Box>
      </Modal>
    </>
  );
};
