import {
  Button,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
  IconButton,
  Grid,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
// import { AddEditState } from './addEditState';

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
    // background: theme.palette.background.tableHeader,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderTop: " 1px solid #e0e0e0",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  divScroll: {
    padding: "0px 24px",
    overflow: "scroll",
    height: "400px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    // height: "100%",
    padding: "16px",
    // overflowY: "scroll",
    background: theme.palette.background.table,
  },
  paper: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  textArea: {
    width: "100%",
    // borderColor: theme.palette.border.textField,
    marginTop: "8px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    resize: "vertical",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
    lineHeight: "0rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const InputSchema = (props) => {
  const classes = useStyles();
  // const dialogContext = React.useContext(DialogContext)
  const alertMessage = React.useContext(AlertContext);
  const [stateData, setStatedata] = React.useState([]);
  const [state, setState] = React.useState({
    input: "",
    inputschema: "",
    isEdit: false,
    open: false,
    // editData: null,
  });
  const [error, setError] = React.useState({
    isinput: false,
    isinputSchema: false,
  });

  React.useEffect(() => {
    setStatedata(props?.createQueue?.inputschema);
  }, [props?.createQueue?.inputschema]);
  // const [state, setState] = React.useState({})

  /* for model open */
  const handleOpen = () => {
    setState({ ...state, open: true, isEdit: false });
  };
  /* for model close */
  const handleClose = () =>
    setState({
      ...state,
      input: "",
      inputschema: "",
      isEdit: false,
      open: false,
    });

  /* on Edit Button Clicked */
  const onEditClicked = (index, data) => {
    setState({
      ...state,
      open: true,
      isEdit: true,
      input: data.input,
      inputschema: data.inputschema,
      id: index,
    });
  };

  /* const onDialogOpen = (index) => {

        dialogContext.setDialog({
            ...dialogContext,
            open: true,
            title: "Are you sure you want to delete the state?",
            body: "",
            positiveBtn: "Confirm",
            negativeBtn: "Cancel",
            onOk: () => onDeletedClicked(index),

        })
    } */

  /*  const onDeletedClicked = (index) => {
         dialogContext.setDialog({
             ...dialogContext,
             open: false,
         })
         debugger;
         const deleteItem = stateData.filter((data, i) => index !== i)
         setStatedata(deleteItem)
         props.updateState("inputschema", deleteItem)
     } */
  const onChangeState = (key, value) => {
    setState({ ...state, [key]: value });
    // props.updateState("inputschema", deleteItem)
  };

  const onSaveClicked = () => {
    let isvalidateinput = state?.input?.trim().length === 0 ? false : true;
    let isvalidateschema =
      state?.inputschema?.trim().length === 0 ? false : true;
    let isvalidschema = validSchema(state?.inputschema);
    let isentity = "undefined";
    if (isvalidschema === true || isvalidschema[0] === true) {
      let jsonData =
        isvalidschema?.length > 0
          ? JSON.parse(isvalidschema[1]?.inputschema)
          : JSON.parse(state?.inputschema);
      jsonData.forEach((v) => {
        isentity = Object.keys(v).includes("entity");
        // console.log(isentity);
      });
    }
    if (isvalidateinput && isvalidateschema && isvalidschema && isentity) {
      let data = isvalidschema?.length > 0 ? isvalidschema[1] : state;
      setStatedata([...stateData, data]);
      handleClose();
      props.updateState("inputschema", [...stateData, data]);
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
    } else {
      if (isvalidschema === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Enter Vaild Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      if (isentity === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Entity is missing in Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
      });
    }
  };

  const validSchema = (val, isUpdate) => {
    debugger;
    try {
      /* let schema = JSON.parse(state?.inputschema);
      if (JSON.parse(state?.inputschema) && isNaN(state?.inputschema)) {
        return true;
      } else {
        return false;
      } */
      let schema = JSON.parse(val);
      if (Array.isArray(schema)) {
        if (schema.length > 0) {
          // console.log(schema);
          let emptyObj = schema.map((l) =>
            Object.keys(l).length === 0 ? false : true
          );

          // console.log(checkEmptyObj);
          return !emptyObj.includes(false);
        } else {
          return false;
        }
        // return true;
      } else if (typeof schema === "object") {
        if (schema !== null && Object.keys(schema)?.length > 0) {
          let objArray = [];
          objArray.push(schema);

          let arrayObj = {
            ...state,
            inputschema: JSON.stringify(objArray, null, 2),
          };
          return [true, arrayObj];
        } else {
          return false;
        }
      }
    } catch (error) {
      return false;
    }
  };

  const onUpdateClicked = async (index) => {
    // debugger;
    let isvalidateinput = state?.input?.trim().length === 0 ? false : true;
    let isvalidateschema =
      state?.inputschema?.trim().length === 0 ? false : true;
    let isvalidschema = validSchema(state?.inputschema, true);
    let isentity = "undefined";
    if (isvalidschema === true || isvalidschema[0] === true) {
      // let jsonData = JSON.parse(state?.inputschema);
      let jsonData =
        isvalidschema?.length > 0
          ? JSON.parse(isvalidschema[1]?.inputschema)
          : JSON.parse(state?.inputschema);
      jsonData.forEach((v) => {
        isentity = Object.keys(v).includes("entity");
        // console.log(isentity);
      });
    }
    if (isvalidateinput && isvalidateschema && isvalidschema && isentity) {
      const editList = stateData.map((item, i) =>
        index === i
          ? isvalidschema?.length > 0
            ? isvalidschema[1]
            : state
          : item
      );
      // setStatedata(editList)
      props.updateState("inputschema", editList);
      handleClose();
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
    } else {
      if (isvalidschema === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Enter Vaild Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      if (isentity === false && isvalidateschema === true) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Entity is missing in Schema",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      setError({
        ...error,
        isinput: state?.input?.trim().length === 0 ? true : false,
        isinputSchema: state?.inputschema?.trim().length === 0 ? true : false,
        // isSchema: validatSchema()
      });
    }
  };

  return (
    <>
      <Paper
        // sx={{
        //   height: "100%",
        //   border: "1px solid #DCDCDC",
        // }}
        className={classes.paper}
        elevation={0}
      >
        <Box className={classes.Header}>
          <Typography variant="body1" sx={{ p: "8px 0" }}>
            Input Schema
          </Typography>
          {!stateData?.length > 0 && (
            <Button
              id={"Add Schema"}
              onClick={() => handleOpen()}
              variant="text"
              // style={{ color: "#3495FF" }}
            >
              {"+ Add Schema"}
            </Button>
          )}
        </Box>
        <Divider />
        <div className={classes.content}>
          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table
              size="small"
              aria-label="customized table"
              className={classes.Table}
            >
              <TableHead className={classes.TableHeader}>
                <TableRow>
                  <StyledTableCell width={15} align="left">
                    <pre> </pre>
                  </StyledTableCell>
                  <StyledTableCell width={15} align="left">
                    S.No
                  </StyledTableCell>
                  <StyledTableCell align="left">Input schema</StyledTableCell>

                  <StyledTableCell width={200} align="center">
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.TableBody}>
                {stateData?.map((list, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell width={15} align="left">
                      <IconButton disableRipple={true}>
                        <DragIndicatorOutlinedIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{list.input}</StyledTableCell>

                    <StyledTableCell width={200} align="center">
                      <IconButton
                        id={"Edit Icon"}
                        onClick={() => onEditClicked(index, list)}
                        disableRipple={true}
                      >
                        <EditOutlinedIcon color="primary" />
                      </IconButton>
                      {/* <IconButton onClick={() => onDialogOpen(index)} disableRipple={true}>
                                            <DeleteOutlineOutlinedIcon color="error" />
                                        </IconButton> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* Model */}
        <Modal
          open={state.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.style}>
            <Box className={classes.Header}>
              <Typography variant="body1">
                {state.isEdit ? "Update Schema" : "Add Schema"}
              </Typography>
            </Box>
            <Divider />
            <div className={classes.divScroll}>
              <Grid container direction="row">
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1"> Input Name </Typography>
                  <TextField
                    margin="dense"
                    value={state?.input}
                    onChange={(e) => onChangeState("input", e.target.value)}
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    error={error?.isinput}
                    helperText={error?.isinput ? "This Field is Required*" : ""}
                  />
                </Grid>
                <Grid item xs={12} sx={{ p: 2 }}>
                  <Typography variant="body1"> Schema </Typography>
                  {error?.isinputSchema ? (
                    <Typography
                      sx={{ ml: 2, padding: 0 }}
                      variant="caption"
                      color="error"
                    >
                      This Field is Required*
                    </Typography>
                  ) : (
                    ""
                  )}
                  <TextareaAutosize
                    aria-label="minimum height"
                    value={state?.inputschema}
                    onChange={(e) =>
                      onChangeState("inputschema", e.target.value)
                    }
                    className={classes.textArea}
                    placeholder="Type Here"
                    style={{
                      width: "100%",
                      borderColor: "#b3b3b3",
                      marginTop: "8px",
                      borderRadius: "5px",
                      padding: "8.5px 14px",
                      resize: "vertical",
                    }}
                    minRows={10}
                    // error={error?.isinputSchema}
                    // helperText={error?.isinputSchema ? "This Field is Required*" : ""}
                  />
                </Grid>
              </Grid>
            </div>

            <Divider />
            <div className={classes.wrapperdiv}>
              <Button
                id={"Cancel Button"}
                onClick={() => handleClose()}
                variant="outlined"
              >
                {"Cancel"}
              </Button>
              {state.isEdit ? (
                <Button
                  id={"Update Button"}
                  onClick={() => onUpdateClicked(state.id)}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Update"}
                </Button>
              ) : (
                <Button
                  id={"Add Button"}
                  onClick={() => onSaveClicked()}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Add"}
                </Button>
              )}
            </div>
          </Box>
          {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
        </Modal>
      </Paper>
    </>
  );
};
