import {
  Button,
  Divider,
  Typography,
  Popover,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
//import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {
  getRepoid,
  getRepoIdStateMapping,
  UpdateRepo,
  UpdateRepoStatus,
  upsertRepo,
} from "../../utils/apiCall";
// import { ProjectInfoContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.table,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 24px 16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 107px)",
    overflowY: "auto",
    background: theme.palette.background.common,
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    borderTop: " 1px solid #e0e0e0",
    background: theme.palette.background.common,
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
    background: theme.palette.background.table,
  },
  content: {
    // padding: "16px",
    height: "52px",
    // overflow: "auto",
    background: theme.palette.background.common,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  Popover: {
    background: theme.palette.background.table,
  },
  Checkbox: {
    color: theme.palette.text.primary,
  },
  icon: {
    width: "30px",
    height: "30px",
    // boxShadow: "0 0 2px rgb(184 184 184)",
    borderRadius: "50%",
    padding: "4px",
    color: theme.palette.text.primary,
  },
}));
export const IncludeActionState = (props) => {
  const classes = useStyles();
  const [lists, setList] = React.useState([]);
  const [state, SetState] = useState([]);
  const [scenariorepid, setScenariosrepid] = useState("");
  // const [isAdd, setAdd] = React.useState(true);
  // const projectinfo = React.useContext(ProjectInfoContext);
  React.useEffect(() => {
    getStatesActionData();
    let scenarioRepo = getRepoid(props.scenariosid);
    scenarioRepo.then((result) => {
      setScenariosrepid(result[0]?.rep_id);
    });
    // eslint-disable-next-line
  }, []);

  const getStatesActionData = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestatesaction",
        filter: `queuestatesaction.statesid=='${props.id}'  && queuestatesaction.activestatus==true`,
        return_fields:
          "{actionid:queuestatesaction.actionid,action:queuestatesaction.action,apiendpoint:queuestatesaction.apiendpoint,chooseform:queuestatesaction.chooseform,choosetype:queuestatesaction.choosetype,_id:queuestatesaction._id}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          let _l = res.data.result;

          if (props.isEdit) {
            for (let i = 0; i < _l.length; i++) {
              for (let j = 0; j < props.state.length; j++) {
                if (_l[i]?.actionid === props.state[j]?.actionid) {
                  Object.assign(_l[i], { isChecked: true });
                } else if (_l[i].isChecked !== true) {
                  Object.assign(_l[i], { isChecked: false });
                }
              }
            }

            setList(_l);
            SetState(_l);
            // console.log("l", _l);
          } else {
            let update_list = _l.map((item) =>
              Object.assign(item, { isChecked: false })
            );
            setList(update_list);
            SetState(update_list);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actions, setActions] = React.useState({
    apiendpoint: "",
    chooseform: "",
    choosetype: "",
  });
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const onChangetrue = (e, id) => {
    let checkedList = lists.map((l, i) =>
      // id === i ? { ...l, isChecked: e.target.checked } : l
      {
        if (id === i) {
          const rep = getRepoIdStateMapping(scenariorepid, props.id);
          rep.then(async (result) => {
            // console.log(result);
            if (result?.length > 0) {
              await getCurrantRepo(
                e.target.checked,
                l._id, //uniqueid for state
                "Task and Document", //rep_type
                l.action, //rep_name
                result[0].rep_id
              );
            }
          });
          return Object.assign(l, { isChecked: e.target.checked });
        } else {
          return l;
        }
      }
    );
    setList(checkedList);
    let filtered = checkedList.filter((l) => l.isChecked === true);
    // setAdd(false);
    SetState(filtered);
  };

  const getCurrantRepo = async (
    isChecked,
    uniqueid,
    reptype,
    repname,
    parentrep
  ) => {
    debugger;
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: `IDM_Repository.parent_rep=='${parentrep}' && IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
        return_fields: "IDM_Repository",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            if (isChecked && res.data.result.length === 0) {
              upsertRepo(reptype, repname, parentrep, uniqueid);
            } else if (isChecked === false) {
              let data = res?.data?.result[0];
              if (data?._key) {
                UpdateRepoStatus(data._key, isChecked);
              }
            } else {
              if (isChecked && res.data.result.length > 0) {
                let data = res?.data?.result[0];
                UpdateRepo(data._key, repname, parentrep, true);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  const onPopoverClose = () => {
    setAnchorEl(null);
  };
  const onSettingClicked = (e, data) => {
    setAnchorEl(e.currentTarget);
    setActions({
      apiendpoint: data?.apiendpoint,
      chooseform: data?.chooseform,
      choosetype: data?.choosetype,
    });
  };
  const onAddClicked = () => {
    let removeisCheck = state.map((l) => {
      delete l.isChecked;
      return l;
    });
    props.includeActionList(props.index, removeisCheck);
    props.onDrawerClosed();
  };
  return (
    <div style={{ height: "100vh" }}>
      <Box className={classes.Header2}>
        <Typography variant="h6">{"Include Action"}</Typography>
      </Box>
      <Divider />
      <div className={classes.actionList}>
        {lists?.map((list, index) => (
          <div key={index}>
            <div className={classes.actionListItem}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => onChangetrue(e, index)}
                    checked={list.isChecked}
                    // sx={{ color: "#000" }}
                    className={classes.Checkbox}
                    // value={list?.action}
                  />
                }
                label={list.action}
              />
              <div>
                <IconButton
                  id={"Setting Icon"}
                  onClick={(e) => onSettingClicked(e, list)}
                  // color="white"
                  // className={classes.Button}
                >
                  <SettingsOutlinedIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </div>
      <div className={classes.content}>
        {/* <Divider /> */}
        <div className={classes.wrapperdiv2}>
          <Button
            id={"Cancel Button"}
            onClick={() => props.onDrawerClosed()}
            variant="outlined"
            // className={classes.Button}
          >
            {"Cancel"}
          </Button>
          <Button
            id={"Add Button"}
            onClick={() => onAddClicked()}
            variant="contained"
            // disabled={isAdd}
            className={classes.Button}
            sx={{ ml: "16px" }}
          >
            {"Add"}
          </Button>
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onPopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid className={classes.Popover} sx={{ p: 2, width: "360px" }}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              {"Choose Type"}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disabled
              defaultValue={actions?.choosetype}
              label="Age"
              fullWidth
              size="small"
              placeholder="Select Type"
              // onChange={handleChange}
            >
              <MenuItem value={actions?.choosetype}>
                {actions?.choosetype}
              </MenuItem>
              {/* <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem> */}
            </Select>
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body1">{"Choose Form"}</Typography>
            <TextField
              id="outlined-name"
              disabled
              defaultValue={actions?.chooseform}
              placeholder="Search"
              fullWidth
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              margin="dense"
              // error={lists?.setListtesName}
              // helperText={lists?.setListtesName === true ? Message.requiredMessage : ""}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1 }}>
            <Typography variant="body1">{`API End Point`}</Typography>
            <TextField
              id="outlined-name"
              // label="Enter State Name"
              // name="statesName"
              defaultValue={actions?.apiendpoint}
              // onChange={(e) => onChangeState(e)}
              disabled
              placeholder="Enter State Name"
              fullWidth
              size="small"
              margin="dense"
              // error={lists?.setListtesName}
              // helperText={lists?.setListtesName === true ? Message.requiredMessage : ""}
            />
          </Grid>
          {/* <div className={classes.wrapperdiv3}>
                    <Button variant='outlined' >
                        {"Cancel"}
                    </Button>
                    <Button variant='contained' sx={{ ml: "16px" }} >
                        {"Save"}
                    </Button>
                </div> */}
        </Grid>
      </Popover>
    </div>
  );
};
