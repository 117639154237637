import React from "react";
import { IconButton, Box, Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    // textAlign: "center",
    // marginTop: "20%"
    // height: "Calc(100vh - 64px)",
    // height: "100%",
    background: theme.palette.background.table,
  },
  title: {
    display: "block",
    marginLeft: theme.spacing(1),
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "52px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    background: theme.palette.background.table,
    borderBottom: "1px solid #DCDCDC",
  },
  styledDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    height: "Calc(100vh - 116px)",
    padding: theme.spacing(3),
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  icon: {
    width: "30px",
    height: "30px",
    // boxShadow: "0 0 2px rgb(184 184 184)",
    borderRadius: "50%",
    padding: "4px",
    color: theme.palette.text.primary,
  },
}));

export const SubHeader = (props) => {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.Header}>
        <div className={classes.container}>
          <IconButton onClick={props.onBackClicked}>
            <ArrowBackIcon className={classes.icon} />
            {/* <ArrowCircleLeftOutlinedIcon /> */}
          </IconButton>
          <Typography className={classes.title} variant="body1" noWrap>
            {props?.title}
          </Typography>
        </div>
        {(props?.value === 0 || props?.value === 1 || props?.value === 2) && (
          <div className={classes.container}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.onBackClicked}
            >
              {props.button1}
            </Button>
            {props?.edit ? (
              <Button
                onClick={() => props.onUpdateClicked()}
                variant="contained"
                // color="secondary"
                className={classes.Button}
                style={{ marginLeft: "16px" }}
              >
                {props.button3}
              </Button>
            ) : (
              <Button
                onClick={() => props.onSaveClicked()}
                variant="contained"
                color="secondary"
                style={{ marginLeft: "16px" }}
                className={classes.Button}
              >
                {props.button2}
              </Button>
            )}
          </div>
        )}
      </Box>
    </>
  );
};
