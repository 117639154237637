import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Paper, IconButton, Chip } from "@mui/material";
// import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DrawerContext } from "../../contexts";
import { ScenarioState } from "./scenarioState";
import { DrawerProps } from "../../utils";
import { IncludeActionState } from "./includeActionState";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import { PossibleState } from "./possibleStates";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { Tooltip } from "@material-ui/core";
import makeStyles from "@mui/styles/makeStyles";

// import axios from "axios";
// import { UpdateRepoStatus } from "../../utils/apiCall";

const useStyles = makeStyles((theme) => ({
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  Checkbox: {
    color: theme.palette.text.primary,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
  // [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  // },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const States = (props) => {
  const classes = useStyles();
  const drawerContext = React.useContext(DrawerContext);

  const [state, setState] = React.useState(props?.state ?? []);

  /* React.useEffect(() => {
    if (props.isEdit) {
      getStateData();
    }
    // readStateData()
    // eslint-disable-next-line
  }, []); */

  // const getStateData = async () => {
  //   try {
  //     let params = {
  //       db_name: `${localStorage.getItem("ProjectDbname")}`,
  //       entity: "queuestates",
  //       filter: `queuestates.queueid=='${props.qid}' &&queuestates.active==true && queuestates.activestatus==true`,
  //       return_fields:
  //         "{_id:queuestates._id,states:queuestates.states,stateid:queuestates.statesid}",
  //     };

  //     let config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: params,
  //     };
  //     await axios(config)
  //       .then((res) => {
  //         debugger;
  //         let _l = res.data.result;
  //         /* if (props?.state.length > 0) {
  //           debugger;
  //           let filterdata = props?.state.filter((l) =>
  //             _l.some((i) => i._id === l._id)
  //           );
  //           setState(filterdata);

  //           let unmatched = props?.state.filter(
  //             (l) => !_l.some((i) => i._id === l._id)
  //           );
  //           // console.log("unmatched", unmatched);
  //           if (unmatched.length > 0) {
  //             unmatched.map((l) => getCurrantRepo(l._id));
  //           }

  //           // let defFilter = compare2Array(false, props?.state, _l, "_id");
  //           // console.log("defFilter", defFilter);
  //         } else {
  //           setState(_l);
  //         } */
  //         // debugger;
  //         setState(_l);
  //         // console.log(update_l);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  /* for deleting data in IDM Repo */
  /* const getCurrantRepo = async (uniqueid) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: `IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
        return_fields: "IDM_Repository",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201 && res?.data?.result.length > 0) {
            let data = res?.data?.result[0];
            UpdateRepoStatus(data._key, false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  }; */

  const listUpdate = (value) => {
    setState(value);
    // console.log("value", value);

    props.updateScenarios("states", value);
  };

  /* const possibleState = (id, arrayList) => {
    let possibleStates = state.map((item, i) =>
      id === i ? Object.assign(item, { possiblestates: arrayList }) : item
    );
    setState(possibleStates);
    props.updateScenarios("states", possibleStates);
    // console.log(possibleStates);
  }; */

  /* const onAddPossibleClick = (id, data) => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <PossibleState
          title="Add Possible State"
          id={id}
          qid={props.qid}
          listUpdate={possibleState}
          onDrawerClosed={onDrawerClosed}
        ></PossibleState>
      ),
      onClose: () => onDrawerClosed(),
    });
  }; */

  /*  const onEditClick = (id, data) => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <PossibleState
          isEdit={true}
          state={data}
          title="Edit Possible State"
          id={id}
          qid={props.qid}
          listUpdate={possibleState}
          onDrawerClosed={onDrawerClosed}
        ></PossibleState>
      ),
      onClose: () => onDrawerClosed(),
    });

  }; */

  const onIncludeActionStateClick = (id, index) => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <IncludeActionState
          includeActionList={includeActionList}
          onDrawerClosed={onDrawerClosed}
          id={id}
          index={index}
          scenariosid={props.scenariosid}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
    // console.log(id);
  };
  const onIncludeActionStateClickEdit = (id, index, data) => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <IncludeActionState
          isEdit={true}
          id={id}
          state={data}
          includeActionList={includeActionList}
          onDrawerClosed={onDrawerClosed}
          index={index}
          data={data}
          scenariosid={props.scenariosid}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
    // console.log(id);
  };
  const includeActionList = (id, list) => {
    // let _list = list.filter((l) => (l?.isChecked === true ? l : null));
    // console.log(_list);
    // let includeAction = state.map((item, i) =>
    //  item?.stateId === id ? { ...item, actionList: list, action: _list } : item)
    let includeAction = state.map((item, i) =>
      id === i ? { ...item, action: list } : item
    );
    setState(includeAction);
    props.updateScenarios("states", includeAction);
    // console.log("possibleStates", includeAction);
  };

  const onIncludeStateClick = () => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <ScenarioState
          qid={props.qid}
          scenariosid={props.scenariosid}
          listUpdate={listUpdate}
          title="Add State"
          onDrawerClosed={onDrawerClosed}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };
  const onIncludeStateEdit = () => {
    drawerContext.setDrawer({
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <ScenarioState
          qid={props.qid}
          scenariosid={props.scenariosid}
          listUpdate={listUpdate}
          isEdit={true}
          title="Add State"
          state={props.state}
          onDrawerClosed={onDrawerClosed}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };
  const onEndChange = (e, index) => {
    let list = state.map((l, i) =>
      index === i ? Object.assign(l, { end: e.target.checked, action: [] }) : l
    );
    setState(list);
    props.updateScenarios("states", list);
  };
  const onDefaultChange = (e, index) => {
    let list = state.map((l, i) =>
      l.default === true
        ? Object.assign(l, { default: false })
        : index === i
        ? Object.assign(l, { default: e.target.checked })
        : l
    );

    //index === i ? Object.assign(l, { default: e.target.checked }) : l)
    setState(list);
    props.updateScenarios("states", list);
  };
  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const handledragend = (result) => {
    // debugger;
    if (!result.destination) return;

    const items = Array.from(state);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    let reorderList = items;

    reorderList.map((l, index) => {
      Object.assign(l, { sequenceno: index + 1 });
      return l;
    });

    // console.log(reorderList);

    setState(reorderList);
    props.updateScenarios("states", reorderList);
  };

  return (
    <div>
      {/* <Paper sx={{ height: "700px", width: "1330px" }}> */}
      {state?.length === 0 ? (
        <Button
          id={"include State Button"}
          style={{ marginRight: "16px", color: "#3495FF" }}
          onClick={() => onIncludeStateClick()}
          type="Text"
        >
          + Include state
        </Button>
      ) : (
        <Button
          id={"include State Button"}
          style={{ marginRight: "16px", color: "#3495FF" }}
          onClick={() => onIncludeStateEdit()}
          type="Text"
        >
          + Include Extra state
        </Button>
      )}

      <div style={{}}>
        <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
          <Table
            size="small"
            aria-label="customized table"
            className={classes.Table}
          >
            <TableHead className={classes.TableHeader}>
              <TableRow>
                <StyledTableCell align="left">&nbsp;</StyledTableCell>
                <StyledTableCell align="left">Sequence Id</StyledTableCell>
                <StyledTableCell align="left">States</StyledTableCell>
                <StyledTableCell align="left">State Type</StyledTableCell>
                {/* <StyledTableCell align="left">Possible State</StyledTableCell> */}
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <DragDropContext onDragEnd={handledragend}>
              <Droppable droppableId="list">
                {(provided) => (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    component="div"
                    className={classes.TableBody}
                  >
                    {state?.map((item, index) => (
                      <Draggable
                        key={item?.sequenceno}
                        draggableId={item?.sequenceno?.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <StyledTableRow
                            key={index}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            component="div"
                          >
                            <StyledTableCell>
                              <div {...provided.dragHandleProps}>
                                <Tooltip
                                  title="Drag And Reorder"
                                  placement="top"
                                >
                                  <DragIndicatorIcon />
                                </Tooltip>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell>{item.sequenceno}</StyledTableCell>
                            <StyledTableCell align="left">
                              {item.states}
                            </StyledTableCell>
                            <StyledTableCell>
                              <FormControlLabel
                                disabled={item.end}
                                control={
                                  <Checkbox
                                    onChange={(e) => onDefaultChange(e, index)}
                                    checked={item.default}
                                    value="default"
                                    className={classes.Checkbox}
                                  />
                                }
                                label="Start"
                              />
                              <FormControlLabel
                                disabled={item.default}
                                control={
                                  <Checkbox
                                    onChange={(e) => onEndChange(e, index)}
                                    value={item.end}
                                    checked={item.end}
                                    className={classes.Checkbox}
                                  />
                                }
                                label="End"
                              />
                            </StyledTableCell>
                            {/*  <StyledTableCell align="left">
                              {item?.possiblestates?.length > 0 ? (
                                <>
                                  <Chip
                                    label={`${item.possiblestates.length} Possiblestate`}
                                    variant="outlined"
                                    sx={{ borderColor: "#3495FF" }}
                                  />

                                  <IconButton
                                    id={"Possible state Edit Icon"}
                                    disableRipple={true}
                                    onClick={() =>
                                      onEditClick(index, item.possiblestates)
                                    }
                                  >
                                    <EditOutlinedIcon color="primary" />
                                  </IconButton>
                                </>
                              ) : (
                                <Button
                                  id={"Add Button"}
                                  type="text"
                                  style={{ color: "#3495FF" }}
                                  onClick={() => onAddPossibleClick(index)}
                                >
                                  + Add
                                </Button>
                              )}
                            </StyledTableCell> */}
                            <StyledTableCell align="center">
                              {item?.action?.length > 0 ? (
                                <>
                                  <Chip
                                    label={`${item?.action?.length} Action`}
                                    variant="outlined"
                                    sx={{ borderColor: "#3495FF" }}
                                  />
                                  <IconButton
                                    id={"Action Edit Icon"}
                                    disableRipple={true}
                                    onClick={() =>
                                      onIncludeActionStateClickEdit(
                                        item?._id,
                                        index,
                                        item?.action
                                      )
                                    }
                                  >
                                    <EditOutlinedIcon color="primary" />
                                  </IconButton>
                                </>
                              ) : (
                                <Button
                                  disabled={item.end}
                                  id={"Include Action Button"}
                                  type="text"
                                  style={{
                                    color: !item.end ? "#3495FF" : "#BDBDBD",
                                  }}
                                  onClick={() =>
                                    onIncludeActionStateClick(item?._id, index)
                                  }
                                >
                                  + Include Action
                                </Button>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </TableContainer>
      </div>
      {/* </Paper>  */}
    </div>
  );
};

// export default Scenarios;
