import React from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Box,
  Typography,
  Modal,
  Tooltip,
  // Autocomplete,
} from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ErrorIcon from "@mui/icons-material/Error";
// import { makeStyles } from '@mui/styles';
import { CreateRule } from "td-rule-builder";
import axios from "axios";
import { performData } from "../../utils";
import { IsRequired } from "../../components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { makeStyles } from "@mui/styles";
// import { ProjectInfoContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    // borderRadius: "8px",
    borderBottom: " 1px solid #e0e0e0",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 24px 16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    padding: "16px",
    height: "calc(100% - 55px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  papers: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  textArea: {
    width: "100%",
    // borderColor: theme.palette.border.textField,
    marginTop: "8px",
    borderRadius: "5px",
    padding: "8.5px 14px",
    resize: "vertical",
    color: theme.palette.text.primary,
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    background: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

export default function QueueDetails(props) {
  const classes = useStyles();

  /*   const [state, setState] = React.useState({
      msgqueuegroup: props?.createQueue?.msgqueuegroup ?? "",
      queuename: props?.createQueue?.queuename ?? "",
      queueId: props?.createQueue?.queueId ?? "",
      queuedescription: props?.createQueue?.queuedescription ?? "",
      kafkatopicname: props?.createQueue?.kafkatopicname ?? "",
      nifiurl: props?.createQueue?.nifiurl ?? ""
    }); */
  // const projectinfo = React.useContext(ProjectInfoContext);
  const [inputSchemaList, setState] = React.useState([]);

  React.useEffect(() => {
    if (props?.isconfig === false) {
      // console.log(props?.isconfig);

      setState(JSON.parse(props?.createQueue?.inputschema[0]?.inputschema));
      // console.log(inputSchemaList);
    }
  }, [props?.isconfig, props?.createQueue]);
  // console.log(inputSchemaList)
  const onChangeState = (key, value) => {
    // setState({ ...state, [key]: value })

    props.updateState(key, value);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // debugger;
    setOpen(false);
  };
  // debugger;
  const onSaveClicked = (data) => {
    // console.log(data)
    if (!data) {
      setOpen(false);
    } else {
      let ruleid = [data?.Result[0]?.properties?.doc?._id];
      // console.log(ruleid)
      // props.updateState("entrycriteria", data)
      UpdateCreatequeue(ruleid);
    }
  };
  const UpdateCreatequeue = async (ruleid) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queue",
          filter: {
            _key: props.createQueue._key,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            entrycriteria: ruleid,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // console.log(res.data.result);

          props?.getSingleRead(props?.pass);
          setOpen(false);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // const schema = props?.createQueue?.inputschema[0]?.inputschema
  // console.log(schema);

  return (
    <>
      <Paper className={classes.papers} elevation={0}>
        <Box className={classes.Header}>
          <Typography variant="body1">Queue Details</Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="body1">Entry Criteria</Typography>
            <Button
              id={"Configure Button"}
              variant="contained"
              disabled={props?.isconfig}
              onClick={handleOpen}
              style={{ backgroundColor: "#66AFFF", margin: "0 16px" }}
            >
              {"Configure"}
            </Button>
            {props?.createQueue?.entrycriteria?.length > 0 ? (
              <Tooltip title="Entry Criteria Completed">
                <CheckCircleIcon color="success" />
              </Tooltip>
            ) : (
              <Tooltip title="Entry Criteria Not Completed">
                <ErrorIcon sx={{ color: "#ffcc00" }} />
              </Tooltip>
            )}
          </div>
        </Box>

        <Divider />

        <div className={classes.content}>
          <Grid container direction="row">
            <Grid item xs={6} sx={{ p: 2 }}>
              <Typography variant="body2">Message Queue Group</Typography>
              <TextField
                id="outlined-name"
                // // label="Name"
                // value={name}
                autoComplete="off"
                name="msgqueuegroup"
                value={props?.createQueue?.msgqueuegroup}
                fullWidth
                size="small"
                margin="dense"
                onChange={(e) => onChangeState("msgqueuegroup", e.target.value)}
              />
            </Grid>

            {/* <Grid item xs={6} sx={{ p: 2 }}>
              <Typography variant="body2">
                {"IDM Id"} <IsRequired />
              </Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                size="small"
                name="idmid"
                value={props?.createQueue?.idmid}
                onChange={(e) => onChangeState("idmid", e.target.value)}
              />
            </Grid> */}
            {/* <Grid item xs={6} sx={{ p: 2 }}>
              <Typography variant="body2">{"Role"}</Typography>
              <Autocomplete
                id="choosetype"
                options={JSON.parse(sessionStorage.getItem("userGroup")) ?? []}
                getOptionLabel={(option) => option.name}
                value={props?.createQueue?.idmrole}
                fullWidth
                name="choosetype"
                // filterSelectedOptions
                multiple
                size="small"
                onChange={(e, value) => onChangeState("idmrole", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    size="small"
                    margin="dense"
                    name="idmrole"
                  />
                )}
              />
            </Grid> */}
          </Grid>
          <Divider></Divider>

          <Grid container direction="row">
            <Grid item xs={6} sx={{ p: 2 }}>
              <Typography variant="body2">
                {"Queue Name"} <IsRequired />
              </Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                size="small"
                name="queuename"
                value={props?.createQueue?.queuename}
                onChange={(e) => onChangeState("queuename", e.target.value)}
              />
            </Grid>

            <Grid item xs={6} sx={{ p: 2 }}>
              <Typography variant="body2"> Queue Id</Typography>
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                size="small"
                autoComplete="off"
                name="queueid"
                disabled
                value={props?.createQueue?.queueid}
                onChange={(e) => onChangeState("queueid", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sx={{ p: 2 }}>
              <Typography variant="body2"> Description </Typography>
              <TextareaAutosize
                aria-label="minimum height"
                placeholder="Type Here"
                className={classes.textArea}
                minRows={5}
                name="queuedescription"
                value={props?.createQueue?.queuedescription}
                onChange={(e) =>
                  onChangeState("queuedescription", e.target.value)
                }
              />
            </Grid>
          </Grid>

          <Divider />

          {/*  <Grid container direction="row" sx={{ mb: 4 }}>

          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography variant='body2' >  Kafka topic Name </Typography>

            <TextField margin="dense" variant="outlined" fullWidth size='small'
              name="kafkatopicname"
              value={props?.createQueue?.kafkatopicname}
              onChange={(e) => onChangeState("kafkatopicname", e.target.value)}
            />
          </Grid>

          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography variant='body2' >  NIFI Process </Typography>


            <TextField margin="dense" variant="outlined" fullWidth size='small'
              name="nifiurl"
              value={props?.createQueue?.nifiurl}
              onChange={(e) => onChangeState("nifiurl", e.target.value)}
            />
          </Grid>



    </Grid> */}
        </div>
      </Paper>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          {props?.isconfig === false ? (
            <CreateRule
              inputSchema={inputSchemaList}
              editId={props?.createQueue?.entrycriteria[0] ?? ""}
              handleClose={(data) => {
                onSaveClicked(data);
              }}
              open={true}
              performvalueData={{
                // stateList: stateList,
                // scenarioList: scenarioList,
                queueList: JSON.parse(sessionStorage.getItem("queueList")),
                // queueList={[props?.createQueue]}
                // queueList: queueList,
                userRole: JSON.parse(sessionStorage.getItem("userAssignee")),
                userGroup: JSON.parse(sessionStorage.getItem("userGroup")),
              }}
              performKeyData={performData}
              dbName={`${process.env.REACT_APP_DB}`}
            />
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </>
  );
}

/* const inputSchema = [{
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "entity": "Speciality",
  "properties": {
    "SpecialtyID": {
      "type": "string"
    }
  },
  "required": [
    "SpecialtyID",
    "Description"
  ]
},
{
  "$schema": "http://json-schema.org/draft-04/schema#",
  "type": "object",
  "entity": "queue1",
  "properties": {
    "SpecialtyID": {
      "type": "string",
    }
  },
  "required": [
    "SpecialtyID",
    "Description"
  ]
}] */
