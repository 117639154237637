import React from "react";
import { Home } from "./home";
import { withNavBars, withAllContexts } from "./../../HOCs";
class HomeParent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // console.log(projectdbname);
    return (
      <>
        <Home />
      </>
    );
  }
}

export default withNavBars(withAllContexts(HomeParent));
