import dotenv from "dotenv";
dotenv.config();

let config = {};

config.api_url = `${process.env.REACT_APP_API_PROTOCAL}${process.env.REACT_APP_API_HOST}${process.env.REACT_APP_API_PORT}${process.env.REACT_APP_API_BASE_URL}`;
config.graphql_url = `${process.env.REACT_APP_GRAPHQL_PROTOCAL}${process.env.REACT_APP_GRAPHQL_HOST}${process.env.REACT_APP_GRAPHQL_PORT}${process.env.REACT_APP_GRAPHQL_BASE_URL}`;
config.graphql_web_socket_url = `${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PROTOCAL}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_HOST}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_PORT}${process.env.REACT_APP_GRAPHQL_WEB_SOCKET_BASE_URL}`;
config.nifi_login_url = `${process.env.REACT_APP_NIFI_LOGIN_PROTOCOL}${process.env.REACT_APP_NIFI_LOGIN_HOST}${process.env.REACT_APP_NIFI_LOGIN_PORT}/api/v1/taskUserSignIn`;
config.NodeRed_url_upsert = `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`;
config.NodeRed_url_read = `${process.env.REACT_APP_NODERED_BASE_URL_READ}`;
config.NodeRed_url_sofeDelete = `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`;
config.Dbname = `${process.env.REACT_APP_DB_Name}`;
config.Metaid = `${process.env.REACT_APP_metaID}`;
config.MetaName = `${process.env.REACT_APP_MetadataDB_Name}`;
config.ruleDB = `${process.env.REACT_APP_DB}`;

export { config };
