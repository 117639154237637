import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import {
  Button,
  Divider,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  TextField,
  Autocomplete,
  Grid,
  Modal,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { styled } from "@mui/material/styles";
import ErrorIcon from "@mui/icons-material/Error";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Addscenario } from "../Scenario/addScenario";
import axios from "axios";
import { AlertContext, DialogContext } from "../../contexts";
import { LinearProgress } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { AlertProps } from "../../utils";
import { getRepoid, softDeleteRepo } from "../../utils/apiCall";
import { IsRequired } from "../../components";
import { CloneIcon } from "../../assets";
import { getQueueStates, upsertRepoClone } from "../home/home";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",

    // position: "sticky",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderTop: " 1px solid #e0e0e0",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    padding: "16px",
    height: "calc(100% - 55px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    border: " 1px solid #e0e0e0",
    overflow: "hidden",
    background: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  papers: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
  // [`&.${tableCellClasses.body}`]: {
  //     fontSize: 14,
  // },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const Scenarios = (props) => {
  const classes = useStyles();
  const dialogContext = React.useContext(DialogContext);
  const alertMessage = React.useContext(AlertContext);
  const [scenarios, setSenarios] = React.useState([]);
  const [switchTab, setSwitchTab] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setClone({
      newname: "",
      cloningData: [],
    });
    setOpen(false);
  };

  const [state, setState] = React.useState({
    isEdit: false,
    editData: null,
  });

  const [cloneScenario, setClone] = React.useState({
    newname: "",
    cloningData: [],
  });

  const onChangeState = (key, value) => {
    setClone({ ...cloneScenario, [key]: value });
  };

  const onCloneClicked = () => {
    let iscloneScenarioname =
      cloneScenario?.newname.trim().length === 0 ? false : true;
    let iscloningDataClone =
      cloneScenario?.cloningData?.length === 0 ? false : true;
    if (iscloneScenarioname && iscloningDataClone) {
      HeyCloneThisScenario(
        cloneScenario.newname,
        cloneScenario.cloningData,
        cloneScenario.cloningData.queueid,
        refreshScenarios
      );
    } else if (!iscloningDataClone) {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "No Clone data selected",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Clone Name can't be Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const refreshScenarios = () => {
    getScenariosData();
    handleClose();
  };
  // const [anchorEl, setAnchorEl] = React.useState(null);

  const [isloading, Setloading] = React.useState(false);

  React.useEffect(() => {
    getScenariosData();
    // eslint-disable-next-line
  }, []);

  const getScenariosData = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenarios",
        filter: `queuescenarios.queueid=='${props.queueid}'&&queuescenarios.active==true && queuescenarios.activestatus==true`,
        sort: "queuescenarios.scenarioname ASC",
        return_fields: "queuescenarios",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      Setloading(true);
      await axios(config)
        .then((res) => {
          setSenarios(res.data.result);
          Setloading(false);
          // console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  /* const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }; */

  // const open = Boolean(anchorEl);
  // const id = open ? 'simple-popover' : undefined;
  const onSwitchTab = () => {
    setSwitchTab(true);
    setState({ ...state, isEdit: false, editData: null });
  };
  const onbackSwitchTab = () => {
    setSwitchTab(false);
  };

  const onEditClicked = (data) => {
    setSwitchTab(true);
    setState({ ...state, isEdit: true, editData: data });
    // console.log(data);
  };

  const onCancelClicked = () => {
    setSwitchTab(false);
    setState({ ...state, isEdit: false });
    getScenariosData();
    // setAnchorEl(null);
  };

  const tabswitchClose = () => {
    setSwitchTab(false);
    getScenariosData();
    // setAnchorEl(null);
  };

  const onStatusChange = (status, _key) => {
    senariosStatus(status, _key);
  };

  const senariosStatus = async (status, _key) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuescenarios",
          filter: {
            _key: `${_key}`,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            status: status,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          getScenariosData();
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Status updated successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onDeleteClicked = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this Scenarios?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => onConfrimDelete(data),
    });
    // console.log(_key);
  };
  const onConfrimDelete = (data) => {
    softdelete(data._key);
    getScenariosStatekey(data._id);
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    /* get but unique id */
    const rep = getRepoid(data._id);
    rep.then(function (result) {
      //  console.log(result.rep_id);
      if (result?.length > 0) {
        softDeleteRepo(result[0]._key);
      }
    });
  };
  const softdelete = async (_key) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenarios",
        filter: `queuescenarios._key=='${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        getScenariosData();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Scenario deleted successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getScenariosStatekey = async (scenarioid) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.scenarioid=='${scenarioid}'  && queuescenariostatemapping.activestatus==true`,
        return_fields: "queuescenariostatemapping",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        const data = res.data.result[0];
        softDeleteScenarioStatemap(data._key);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const softDeleteScenarioStatemap = async (_key) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping._key=='${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        getScenariosData();
        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  let isCloneDisable = scenarios.length === 0 ? true : false;

  return (
    <>
      {switchTab ? (
        state?.isEdit ? (
          <Addscenario
            isEdit={state.isEdit}
            editData={state.editData}
            onbackSwitchTab={onbackSwitchTab}
            onCancelClicked={onCancelClicked}
            createQueue={props?.createQueue}
            scenariosList={scenarios}
            tabswitchClose={tabswitchClose}
          />
        ) : (
          <Addscenario
            isNew={true}
            queueid={props.queueid}
            onCancelClicked={onCancelClicked}
            onbackSwitchTab={onbackSwitchTab}
            tabswitchClose={tabswitchClose}
            createQueue={props?.createQueue}
          />
        )
      ) : (
        <Paper className={classes.papers} elevation={0}>
          <Box className={classes.Header}>
            <Typography variant="body1">Scenario</Typography>
            <div>
              <Button
                id={"Clone Scenario Button"}
                onClick={() => handleOpen()}
                variant="outlined"
                color="primary"
                disabled={isCloneDisable}
                startIcon={<CloneIcon />}
                style={{ marginRight: "8px" }}
              >
                {"Clone Scenario"}
              </Button>
              <Button
                id={"Add Scenario Button"}
                onClick={() => onSwitchTab()}
                variant="contained"
                className={classes.Button}
                // style={{ color: "#3495FF" }}
              >
                {"+ Add Scenario"}
              </Button>
            </div>
          </Box>
          <Divider />
          <div className={classes.content}>
            {scenarios?.length !== 0 ? (
              <TableContainer
                component={Paper}
                sx={{ width: "auto", overflowX: "auto" }}
              >
                <Table
                  size="small"
                  aria-label="customized table"
                  className={classes.Table}
                >
                  <TableHead className={classes.TableHeader}>
                    <TableRow>
                      <StyledTableCell width={15} align="left">
                        S.No
                      </StyledTableCell>
                      <StyledTableCell align="left">Scenario</StyledTableCell>
                      <StyledTableCell align="left">
                        Entry Criteria
                      </StyledTableCell>
                      <StyledTableCell width={35} align="center">
                        Status
                      </StyledTableCell>
                      <StyledTableCell width={150} align="center">
                        Action
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.TableBody}>
                    {scenarios?.map((scenario, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="left">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {scenario?.scenarioname}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          Configure
                          {scenario?.scenariocriteria?.length > 0 ? (
                            <Tooltip title="Scenario Criteria Completed">
                              <IconButton
                                disableFocusRipple={true}
                                disableRipple={true}
                              >
                                <CheckCircleIcon color="success" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Scenario Criteria Not Completed">
                              <IconButton disableRipple={true}>
                                <ErrorIcon sx={{ color: "#ffcc00" }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Switch
                            checked={scenario?.status}
                            onChange={(e) =>
                              onStatusChange(e.target.checked, scenario?._key)
                            }
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton
                            id={"Edit Icon"}
                            onClick={() => onEditClicked(scenario)}
                          >
                            <EditOutlinedIcon color="primary" />
                          </IconButton>

                          <IconButton
                            id={"Delete Icon"}
                            onClick={() => onDeleteClicked(scenario)}
                          >
                            <DeleteOutlineOutlinedIcon color="error" />
                          </IconButton>

                          {/* <Popover
                                                        id={id}
                                                        open={open}
                                                        anchorEl={anchorEl}
                                                        onClose={handleClose}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <List >
                                                            <ListItemButton onClick={() => onEditClicked(scenario)}>
                                                                <ListItemIcon>
                                                                    <EditOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Edit" />
                                                            </ListItemButton>
                                                            <ListItemButton onClick={() => onDeleteClicked(scenario?._key)}>
                                                                <ListItemIcon  >
                                                                    <DeleteOutlineOutlinedIcon />
                                                                </ListItemIcon>
                                                                <ListItemText primary="Delete" />
                                                            </ListItemButton>
                                                        </List>
                                                    </Popover> */}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : isloading ? (
              <LinearProgress />
            ) : (
              <Typography align="center">
                No Scenario Added In This Queue Yet, Add New Scenario!
              </Typography>
            )}
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.style}>
              <Box className={classes.Header}>
                <Typography variant="body1">{"Clone Scenarios"}</Typography>
              </Box>
              <Divider />
              <div style={{ padding: "24px 16px" }}>
                <Grid container>
                  <Grid item xs={12} lg={12} sx={{ py: 1 }}>
                    <Typography variant="body2" gutterBottom>
                      {"New Scenario Name"}
                      <IsRequired />
                    </Typography>
                    <TextField
                      onChange={(e) => onChangeState("newname", e.target.value)}
                      placeholder="Enter New Name"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="states"
                      // error={err}
                      // helperText={err === true ? Message.requiredMessage : ""}
                      value={cloneScenario?.newname}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ py: 1 }}>
                    <Typography variant="body2" gutterBottom>
                      {"Select Scenarios"}
                    </Typography>
                    <Autocomplete
                      id="choosetype"
                      options={scenarios}
                      getOptionLabel={(option) => option.scenarioname || ""}
                      value={cloneScenario?.cloningData}
                      fullWidth
                      name="choosetype"
                      // filterSelectedOptions
                      size="small"
                      onChange={(e, value) =>
                        onChangeState("cloningData", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          size="small"
                          name="idmrole"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
              <Divider />

              <div className={classes.wrapperdiv}>
                <Button
                  id={"Cancel Button"}
                  onClick={() => handleClose()}
                  variant="outlined"
                >
                  {"Cancel"}
                </Button>

                <Button
                  id={"Update Button"}
                  onClick={() => onCloneClicked()}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Clone"}
                </Button>
              </div>
            </Box>
            {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
          </Modal>
        </Paper>
      )}
    </>
  );
};

// export default Scenarios;

export const HeyCloneThisScenario = async (
  newname,
  cloningData,
  QID,
  refreshScenarios = () => false,
  fromQueue,
  parentrepid
) => {
  /* Getting Rule data of existing scenario */
  /*   let readRule = await getRuleData(cloningData?.scenariocriteria[0]);
  let existRuleData = readRule.result[0];
  let upsertCloneRule = await upsertRule(existRuleData); */
  let upsertCloneRule = await heyCloneThisRule(
    cloningData?.scenariocriteria[0] ?? ""
  );

  let doc = {
    bgcolor: cloningData?.bgcolor,
    // queueid: fromQueue ? QID : cloningData?.queueid,
    queueid: QID,
    scenarioid: "",
    scenariocriteria: upsertCloneRule,
    scenariodescription: cloningData?.scenariodescription,
    scenarioname: newname,
    status: cloningData?.status,
    textcolor: cloningData?.textcolor,
    version: cloningData?.version,
    active: cloningData?.active,
  };

  let repoId = await getRepoidClone(QID);

  /*cloning scenario insert */
  let parentid = fromQueue ? parentrepid : repoId;

  let scenarioInsertData = await upsertSenarios(doc, refreshScenarios);

  let scenarioRepo = await upsertRepoClone(
    "Task and Document",
    scenarioInsertData.scenarioname,
    parentid,
    scenarioInsertData._id
  );

  /* Getting existing scenario state mapping */
  let existingStateMappingData = await readStateMapping(cloningData?._id);

  let existing_states = existingStateMappingData?.result[0]?.states ?? [];

  let QclonedStateList = await getQueueStates(QID);

  let clonedstateList = QclonedStateList?.result ?? [];

  let CloneStatesScenario = await StateMappingUpdate(
    existing_states,
    fromQueue,
    clonedstateList
  );

  await insertRepoStateMapping(CloneStatesScenario, scenarioRepo.rep_id);

  let existing_workFlow =
    existingStateMappingData?.result[0]?.workflowdiagram ?? [];

  let clonedWorkflow = await existing_workFlow?.map(async (l) => {
    debugger;
    if (l.data && l.data.ruleid && l.data.value) {
      let workflowRule = await heyCloneThisRule(l.data.ruleid ?? "");
      let state = existing_states.filter(
        (a) => a.states === l.data.value.status.states
      );
      return {
        ...l,
        data: {
          ...l.data,
          ruleid: workflowRule[0] ?? "",
          value: { ...l.data.value, status: state[0] },
        },
      };
    } else if (l.data && l.data.value) {
      let state = existing_states.filter(
        (a) => a.states === l.data.value.status.states
      );
      return {
        ...l,
        data: { ...l.data, value: { ...l.data.value, status: state[0] } },
      };
    } else {
      return l;
    }
  });

  Promise.all(clonedWorkflow).then(function (results) {
    let doc = {
      // queueid: fromQueue ? QID : existingStateMappingData.result[0].queueid,
      queueid: QID,
      scenarioid: scenarioInsertData?._id,
      version: "1",
      active: true,
      // states: existingStateMappingData.result[0].states,
      states: CloneStatesScenario,
      workflow: [],
      workflowdiagram: results,
    };
    upsertStateMapping(doc);
  });
};

export const upsertSenarios = async (doc, refreshScenarios) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenarios",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: doc,
      },
    ];

    // console.log(params);

    let _ = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );

    let res = _.data;
    if (res.Code === 201) {
      refreshScenarios();
      return res.Result[0]?.properties?.doc;
    }
  } catch (error) {
    console.log(error);
  }
};

export const AxiosCall = async (url, params) => {
  let config = {
    method: "post",
    url: `${url}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: params,
  };
  let data = await axios(config);
  return data;
};

export const getRuleData = async (ruleid) => {
  try {
    let params = {
      db_name: `${process.env.REACT_APP_RULE_DB_NAME}`,
      entity: "Rules",
      filter: `Rules._id=='${ruleid}' && Rules.activestatus==true `,
      return_fields: "Rules",
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const upsertRule = async (doc) => {
  try {
    let params = [
      {
        db_name: `${process.env.REACT_APP_RULE_DB_NAME}`,
        entity: "Rules",
        is_metadata: true,
        metadataId: "99a84d6a-1172-4796-8416-172982a3651a",
        doc: {
          id: 0,
          // groupId: doc.groupId,
          ruleName: doc?.ruleName,
          ruleMeta: doc?.ruleMeta,
          uiMeta: doc?.uiMeta,
          facts: { facts: doc?.facts },
          actionTrue: doc?.actionTrue,
          actionFalse: doc?.actionFalse,
          priority: doc?.priority ? parseInt(doc.priority) : 1,
        },
      },
    ];
    // console.log(params);

    let data = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );
    return data?.data?.Result[0]?.properties?.doc?._id;
  } catch (error) {
    console.log(error);
  }
};

const upsertStateMapping = async (scenarioStatDoc) => {
  try {
    let params;
    params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: scenarioStatDoc,
      },
    ];
    let data = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_UPSERT,
      params
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

const readStateMapping = async (scenarioid) => {
  // debugger
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "queuescenariostatemapping",
      filter: `queuescenariostatemapping.scenarioid=='${scenarioid}' && queuescenariostatemapping.active==true && queuescenariostatemapping.activestatus==true`,
      return_fields: "queuescenariostatemapping",
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    return res?.data;
  } catch (error) {
    console.log(error);
  }
};

export const heyCloneThisRule = async (ruleid) => {
  if (ruleid.length > 0) {
    let readRule = await getRuleData(ruleid);
    let existRuleData = readRule.result[0];
    let upsertCloneRule = await upsertRule(existRuleData);
    return [upsertCloneRule];
  } else {
    return [];
  }
};

export const StateMappingUpdate = async (
  existing_states,
  fromQueue,
  QclonedStateList
) => {
  debugger;
  if (fromQueue) {
    if (existing_states.length > 0 && QclonedStateList.length > 0) {
      let _l = await existing_states?.filter((l) =>
        QclonedStateList?.map((j) => {
          if (j.states === l.states) {
            let actions = getAction(l.action, j.queuestatesaction);
            return Object.assign(l, {
              stateid: j.statesid,
              _id: j._id,
              action: actions,
            });
          }
          return l;
          // ? Object.assign(l, { stateid: j.statesid, _id: j._id })
          // : false;
        })
      );

      // let clondedUpdateAction = await mapActionwithStates(_l);

      // Promise.all(clondedUpdateAction).then(function (results) {
      //   console.log(results);
      //   return results;
      // });
      // console.log(queuqStatesAction);
      return _l;
    } else {
      return existing_states;
    }
  } else {
    return existing_states;
  }
};

const getAction = (mappingAction, clonedAction) => {
  let action = mappingAction?.filter((l) =>
    clonedAction?.map((j) =>
      l.action === j.action
        ? Object.assign(l, {
            actionid: j.actionid,
            _id: j._id,
          })
        : false
    )
  );
  // console.log(action);
  return action;
};

export const getRepoidClone = async (uniqueid) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "IDM_Repository",
      filter: `IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
      return_fields: "IDM_Repository",
    };
    let res = await AxiosCall(
      process.env.REACT_APP_NODERED_BASE_URL_READ,
      params
    );
    let _ = res?.data;
    return _?.result[0]?.rep_id;
  } catch (error) {}
};

export const insertRepoStateMapping = async (stateMappedList, parent_rep) => {
  debugger;
  if (stateMappedList?.length > 0) {
    await stateMappedList?.map(async (l) => {
      let upsertStateRepo = await upsertRepoClone(
        "Task and Document",
        l.states,
        parent_rep,
        l._id
      );
      if (l?.action?.length > 0) {
        l.action?.map(async (act) => {
          await upsertRepoClone(
            "Task and Document",
            act.action,
            upsertStateRepo.rep_id,
            act._id
          );
        });
      }
    });
  }
};

// const getStatesActionData = async (statesid, actioname) => {
//   try {
//     let params = {
//       db_name: `${localStorage.getItem("ProjectDbname")}`,
//       entity: "queuestatesaction",
//       filter: `queuestatesaction.statesid=='${statesid}'&& queuestatesaction.action=='${actioname}' && queuestatesaction.activestatus==true`,
//       return_fields:
//         "{actionid:queuestatesaction.actionid,action:queuestatesaction.action,apiendpoint:queuestatesaction.apiendpoint,chooseform:queuestatesaction.chooseform,choosetype:queuestatesaction.choosetype,_id:queuestatesaction._id}",
//     };
//     let res = await AxiosCall(
//       process.env.REACT_APP_NODERED_BASE_URL_READ,
//       params
//     );
//     let _ = await res?.data;
//     return _?.result[0];
//   } catch (error) {
//     console.log(error);
//   }
// };
