/**
 * @author Gokul kannan
 * @email gokul.kumar@ainqa.com
 * @create date 2022-03-07
 * @modify date 2022-03-07
 * @desc This File has all the navigation routes and it's rendering components will bes here.
 */

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Redirect,
} from "react-router-dom";
import { Routes } from "./routes";
// import PrivateRoute from "./privateRouter";

import { NotFound, HomeParent, CreateQueue } from "./../screens";
// import BasicFlow from "../components/workFlow";

const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        {/* Login Route */}
        {/*  {
          <Route exact path={Routes.login}>
            <Login />
          </Route>
        } */}

        {/* Home Routes */}
        <Route exact path={Routes.home}>
          <HomeParent />
        </Route>

        {/* Create Queue Routes */}
        <Route exact path={Routes.createQueue}>
          <CreateQueue isconfig={false} />
        </Route>

        {/* Config Queue Routes */}
        <Route exact path={Routes.Config}>
          <CreateQueue isconfig={true} />
        </Route>
        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
