import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import {
  Divider,
  Paper,
  Modal,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
// import Select from 'react-select'
import axios from "axios";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps, validateSpecialCharacter } from "../../utils";
import LinearProgress from "@mui/material/LinearProgress";
import { IsRequired } from "../../components";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderTop: " 1px solid #e0e0e0",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 105px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  content: {
    padding: "16px",
    overflowY: "scroll",
    height: "calc(100% - 122px)",
  },
  dive: {
    // padding: "16px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.action.hover,
    color: theme.palette.text.primary,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const MessageConfiguration = (props) => {
  const [state, setState] = React.useState(false);
  const [messages, setMessage] = React.useState([]);
  const dialogContext = React.useContext(DialogContext);
  // const alert = React.useState(AlertContext)
  const alertMessage = React.useContext(AlertContext);
  // const projectinfo = React.useContext(ProjectInfoContext);
  const classes = useStyles();
  const [isloading, setisloading] = React.useState(false);
  const [edit, setedit] = React.useState({
    isEdit: false,
    editData: null,
  });
  React.useEffect(() => {
    getMessageCatalog();
    // eslint-disable-next-line
  }, []);

  const getMessageCatalog = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuemessagecatalogue",
        filter:
          "queuemessagecatalogue.activestatus==true and queuemessagecatalogue.type!='PPQ'",
        return_fields: "queuemessagecatalogue",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      setisloading(true);
      await axios(config).then((res) => {
        setMessage(res.data.result);
        setisloading(false);
        console.log(res.data.result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addmessagePop = () => {
    setState(true);
  };

  const onclosepop = () => {
    setState(false);
    setedit({
      isEdit: false,
      editData: null,
    });
  };
  const onEditClicked = (data) => {
    setState(true);
    // console.log(data);
    setedit({
      isEdit: true,
      editData: data,
    });
  };
  const reloadState = () => {
    onclosepop();
    getMessageCatalog();
  };

  /*  const toastMessage = (message) => {
         alert.setSnack({
             ...alert,
             open: true,
             severity: AlertProps.severity.error,
             msg: message,
             vertical: AlertProps.vertical.top,
             horizontal: AlertProps.horizontal.center,
         })
     } */

  const onDeletClicked = (_key) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => onDeletedClicked(_key),
    });
    // toastMessage("message")
  };
  const onDeletedClicked = (_key) => {
    softdelete(_key);
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
  };
  const softdelete = async (_key) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuemessagecatalogue",
        filter: `queuemessagecatalogue._key == '${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        getMessageCatalog();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Message Deleted Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // console.log(res);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box className={classes.Header}>
        <Typography variant="body1">Message Configuration</Typography>
        <Button
          id={"Add Messade Button"}
          onClick={() => addmessagePop()}
          variant="text"
          // style={{ color: "#3495FF" }}
        >
          {"+ Add Message"}
        </Button>
      </Box>
      <Divider />
      <div className={classes.content}>
        {messages?.length === 0 ? (
          isloading ? (
            <LinearProgress color="primary" />
          ) : (
            <Typography align="center">No Messages Add still yet!</Typography>
          )
        ) : (
          <TableContainer component={Paper}>
            <Table
              size="small"
              aria-label="customized table"
              className={classes.Table}
            >
              <TableHead className={classes.TableHeader}>
                <TableRow>
                  <StyledTableCell align="left">S.No</StyledTableCell>
                  <StyledTableCell align="left">Message Id</StyledTableCell>
                  <StyledTableCell align="left">Message Text</StyledTableCell>
                  <StyledTableCell align="left">Status Code</StyledTableCell>
                  <StyledTableCell align="left">
                    Attach Remarks From Message Catalog
                  </StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody className={classes.TableBody}>
                {messages?.map((message, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">
                      {message?.messageid}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {message?.title}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {message?.status}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {message?.remarks}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <IconButton
                        id="Edit Icon"
                        disableRipple={true}
                        onClick={() => onEditClicked(message)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        id={"Delete Icon"}
                        disableRipple={true}
                        onClick={() => onDeletClicked(message?._key)}
                      >
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className={classes.dive}>
        <Divider />
        <div className={classes.wrapperdiv}>
          <Button
            id={"Model Close Button"}
            onClick={() => props.handleClose()}
            variant="outlined"
          >
            {"Close"}
          </Button>
          {/* <Button variant='contained' sx={{ ml: "16px" }} >
                        {"Save"}
                    </Button> */}
        </div>
      </div>

      <Modal
        open={state}
        onClose={onclosepop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.style}>
          {edit?.isEdit ? (
            <AddMessage
              reloadState={reloadState}
              isEdit={edit?.isEdit}
              data={edit?.editData}
            />
          ) : (
            <AddMessage reloadState={reloadState} />
          )}
        </Box>
      </Modal>
    </>
  );
};

const AddMessage = (props) => {
  const classes = useStyles();
  const alertMessage = React.useContext(AlertContext);
  // const projectinfo = React.useContext(ProjectInfoContext);
  const [state, setState] = React.useState(
    props?.isEdit
      ? props?.data
      : {
          messageid: "",
          title: "",
          status: "",
          remarks: "",
          version: "",
          type: "TDW",
          errormessage: {
            errormessageText: false,
            errormessageID: false,
          },
        }
  );

  const HandleChange = (key, value) => {
    let checkSpecialChar = validateSpecialCharacter(value);
    if (!checkSpecialChar) {
      setState({ ...state, [key]: value });
    }
  };

  const onSaveClicked = () => {
    // debugger;
    let isMessageText = state?.title.trim().length === 0 ? false : true;
    let isMessageId = state?.status.trim().length === 0 ? false : true;
    let isMessageCatalog = state?.remarks?.trim().length === 0 ? false : true;
    if (isMessageText && isMessageId && isMessageCatalog) {
      // console.log(state);
      if (props?.isEdit) {
        updateMessagecatalog(props?.isEdit, state);
      } else {
        upsertMessagecatalog(state);
      }
    } else {
      state.errormessage = {
        errormessageText: state?.title?.trim().length === 0 ? true : false,
        errormessageID: state?.status?.trim().length === 0 ? true : false,
        errormessageCatalog: state?.remarks?.trim().length === 0 ? true : false,
      };
      setState({ ...state });
    }
    // props.updateState(state)
    // props.onclosepop()
  };
  const upsertMessagecatalog = async (state) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuemessagecatalogue",
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            messageid: "",
            title: state?.title,
            remarks: state?.remarks,
            status: state?.status,
            type: state?.type,
            version: "",
            active: true,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        console.log(res.data.result);
        props.reloadState();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Message Added Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateMessagecatalog = async (isEdit, state) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuemessagecatalogue",
          filter: {
            _key: state?._key,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: state,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        console.log(res.data.result);
        props.reloadState();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Message Updated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Box className={classes.Header2}>
        <Typography variant="body1">
          {props?.isEdit ? "Update Message" : "Add Message"}
        </Typography>
      </Box>
      <Divider />
      <div
        style={{
          padding: "16px",
          overflowY: "scroll",
          height: "calc(100% - 114px)",
        }}
      >
        <Grid container direction="row">
          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography variant="body2">
              {"Message Text "}
              <IsRequired />
            </Typography>
            <TextField
              value={state?.title}
              margin="dense"
              variant="outlined"
              required
              fullWidth
              size="small"
              name="Message text"
              onChange={(e) => HandleChange("title", e.target.value)}
              error={state?.errormessage?.errormessageText}
              helperText={
                state?.errormessage?.errormessageText
                  ? "This Field is Required*"
                  : ""
              }
            />
          </Grid>

          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography variant="body2">
              {"Status Code"}
              <IsRequired />
            </Typography>
            <TextField
              margin="dense"
              variant="outlined"
              fullWidth
              size="small"
              value={state?.status}
              name="status"
              required
              onChange={(e) => HandleChange("status", e.target.value)}
              error={state?.errormessage?.errormessageID}
              helperText={
                state?.errormessage?.errormessageID
                  ? "This Field is Required*"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={6} sx={{ p: 2 }}>
            <Typography sx={{ marginBottom: "8px" }} variant="body2">
              {"Attach Remarks From Message Catalog "}
              <IsRequired />
            </Typography>

            {/*  <Select value={state?.remarks} placeholder="Search" options={options} name="remarks" onChange={(value) => HandleChange("remarks", value)} /> */}
            {/*  <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state?.remarks} placeholder="Search"
                            name="remarks"
                            fullWidth
                            size='small'
                            onChange={(e) => HandleChange("remarks", e.target.value)}
                            error={state?.errormessage?.errormessageCatalog}
                            helperText={state?.errormessage?.errormessageCatalog ? "This Field is Required*" : ""}
                        >
                            <MenuItem value={"The payload is invalid, Please Cross-Check"}>The payload is invalid, Please Cross-Check</MenuItem>
                            <MenuItem value={"No queue found on this item, Please Cross-Check"}>No queue found on this item, Please Cross-Check</MenuItem>
                            <MenuItem value={"No Scenario found on this item, Please Cross-Check"}>No Scenario found on this item, Please Cross-Check</MenuItem>
                        </Select> */}
            <TextField
              value={state?.remarks}
              margin="dense"
              variant="outlined"
              required
              fullWidth
              size="small"
              name="Message text"
              onChange={(e) => HandleChange("remarks", e.target.value)}
              error={state?.errormessage?.errormessageCatalog}
              helperText={
                state?.errormessage?.errormessageCatalog
                  ? "This Field is Required*"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </div>
      <div style={{ height: "52px" }}>
        <Divider />
        <div className={classes.wrapperdiv}>
          <Button
            id={"Cancel Button"}
            onClick={() => props.reloadState()}
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            id={"Save Button"}
            onClick={() => onSaveClicked()}
            variant="contained"
            sx={{ ml: "16px" }}
            className={classes.Button}
          >
            {props?.isEdit ? "Update" : "Save"}
          </Button>
        </div>
      </div>
    </>
  );
};
