import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
// import { LocalStorageKeys } from '../../utils';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../router/routes';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import { Button, IconButton, Grid, TextField, Typography } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { config } from '../../config';
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#ECF0F7"
    }
}))

export const Login = props => {

    const history = useHistory();
    const classes = useStyles();
    const [error, setError] = React.useState()
    const [values, setValues] = React.useState({
        email_id: "admin@ainqaplatform.in",
        password: "admin@123",
        error: {
            email_id: "",
            password: "",
        },
        message: '',
        showPassword: false
    });
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const onLogin = async () => {

        const __options = {
            method: "POST",
            headers: {
                "Content-Type": "text/plain"
            },
            body: {},
        };
        let params = {
            email: values.email_id,
            password: values.password,
        }
        await fetch(`${config.nifi_login_url}`, {
            ...__options,
            body: btoa(JSON.stringify({
                username: params.email,
                password: params.password,
            })),
        })
            .then(response => response.json())
            .then((result) => {

                localStorage.setItem("authtoken", result.access_token);
                if (result.access_token) {
                    history.push(Routes.home);
                } else {
                    setError(result.error_description)
                }
            })
            .catch((error) => {

                setValues({
                    ...values,
                    message: "Invalid Credentials"
                })
            })
        // debugger;
        // history.push(Routes.home);
        // keyClock= await keyClock.json()


    }

    return <div className={classes.root}>
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                    m: 1,
                    width: 350,
                    height: 375
                }
            }}
        >
            <Paper
                elevation={2}
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    borderRadius: "16px",
                    p: 3
                }}
            >
                <div style={{ margin: "16px 0" }}>
                    <Typography style={{ fontWeight: "800" }} gutterBottom variant="h5">
                        Sign Up
                    </Typography>
                    <Typography gutterBottom variant="body2">
                        Sign In With Your Email and Password
                    </Typography>
                </div>

                <Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            User Id <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <TextField margin="dense" fullWidth size="small" value={values.email_id} onChange={(e) =>
                            setValues({ ...values, email_id: e.target.value, error: { ...values.error, email_id: "" }, message: "" })
                        } />
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "8px"
                            }}
                        >
                            <Typography variant="body2">
                                Password <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <Typography variant="body2" style={{ color: "#007AFF" }}>
                                Forgot Password?
                            </Typography>
                        </div>
                        <TextField
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            size="small"
                            fullWidth
                            name="password"
                            margin="dense"
                            onChange={(e) =>
                                setValues({ ...values, password: e.target.value, error: { ...values.error, password: "" }, message: "" })
                            }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            // onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            disableRipple={true}
                                            disableFocusRipple={true}
                                        >
                                            {!values.showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* <TextField type="password" margin="dense" fullWidth size="small" /> */}
                    </Grid>
                    {error && <Grid item xs={12}>
                        <Typography variant="body2" style={{ color: "red" }}>{error}</Typography>
                    </Grid>}
                </Grid>
                <Button
                    fullWidth
                    variant="contained"
                    style={{ margin: "16px 0 8px 0", backgroundColor: "#007AFF" }}
                    onClick={onLogin}
                    size='large'
                >
                    {"Sign In"}
                </Button>
            </Paper>
        </Box>
    </div>
}