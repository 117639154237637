import React from "react";
import BasicFlow from "../../components/workFlow";
import { Typography } from "@mui/material";

const workFlow = [
  {
    type: "Start",
    color: "#FFD174",
  },
  {
    type: "State",
    color: "#9ECCFF",
  },
  {
    type: "Action",
    color: "#CE42FF",
  },
  {
    type: "End State",
    color: "#8ADD1C",
  },
];
export const WorkFlow = (props) => {
  const [inputSchemaList, setState] = React.useState([]);

  React.useEffect(() => {
    setState(JSON.parse(props?.createQueue?.inputschema[0]?.inputschema));
  }, [props?.createQueue?.inputschema]);
  // console.log(inputSchemaList);
  return (
    <>
      <div style={{ display: "flex", marginRight: "8px" }}>
        {workFlow.map((item, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 8px",
            }}
          >
            <span
              style={{
                backgroundColor: `${item.color}`,
                height: "3px",
                width: "20px",
                margin: "0 4px",
              }}
            ></span>
            <Typography variant="body2">{item.type}</Typography>
          </div>
        ))}
      </div>
      <BasicFlow
        inputSchemaList={inputSchemaList}
        queueList={props.createQueue}
        stateList={props.scenarioStatemap.states}
        optionKey={"states"}
        scenarioStatemap={props.scenarioStatemap}
        ScenariosList={props.ScenariosList}
        updateScenarios={props.updateScenarios}
        currentScenarios={props.currentScenarios}
      />
    </>
  );
};
