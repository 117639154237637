import {
  Button,
  Chip,
  Divider,
  Modal,
  Paper,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
  Grid,
  Tooltip,
  // Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { DrawerProps, AlertProps } from "../../utils";
import { styled } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
import { Message } from "../../utils/messages";
import {
  DialogContext,
  DrawerContext,
  QueueContext,
  AlertContext,
  BackdropContext,
} from "../../contexts";
import SwapHorizRoundedIcon from "@material-ui/icons/SwapHorizRounded";
// import { AddEditState } from './addEditState';
import { DrawerAddAction } from "./statesAndAction.addAction";
import axios from "axios";
import { ColorTextBox, FileUpload, IsRequired } from "../../components";
import {
  getRepoid,
  scenarioStateMappingUpdate,
  UpdateRepo,
  updateRepoMappedInScenario,
} from "../../utils/apiCall";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "16px",
    borderTop: " 1px solid #e0e0e0",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    height: "calc(100% - 55px)",
    padding: "16px",
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "8px",
    background: theme.palette.background.table,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  papers: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
    // color: theme.palette.common.black,
    lineHeight: "0rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.warning.main,
  },
  tooltip: {
    backgroundColor: theme.palette.warning.main,
  },
}));

export function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow open={props.open} classes={classes} {...props} />;
}

export const StatesAndAction = (props) => {
  const classes = useStyles();
  const drawerContext = React.useContext(DrawerContext);
  const dialogContext = React.useContext(DialogContext);
  const backdropContext = React.useContext(BackdropContext);
  const queueContext = React.useContext(QueueContext);
  const alertMessage = React.useContext(AlertContext);
  // const projectinfo = React.useContext(ProjectInfoContext);
  const [modelState, Setmodel] = React.useState({
    queueid: props.queue,
    statesid: "",
    states: "",
    bgcolor: "",
    icon: "",
    textcolor: "",
    // idmrole: [],
    version: "",
    active: true,
    open: false,
    isEdit: false,
  });

  const [isloading, setloading] = React.useState(false);
  const [stateData, setStatedata] = React.useState([]);
  const [err, seterr] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  /* Reading states data */
  React.useEffect(() => {
    getStatesData();
    // eslint-disable-next-line
  }, []);

  const getStatesData = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestates",
        filter: `queuestates.queueid=='${props.queue}' &&queuestates.active==true && queuestates.activestatus==true`,
        return_fields: `merge(unset(queuestates,'createdby','createddate','updatedby','updatedate'),{queuestatesaction:(for queuestatesaction in queuestatesaction filter queuestatesaction.statesid==queuestates._id && queuestatesaction.activestatus==true return unset(queuestatesaction,'createdby','createddate','updatedby','updatedate'))})`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      setloading(true);
      await axios(config)
        .then((res) => {
          setStatedata(res.data.result);

          // console.log(res.data.result);
          queueContext.setQueue({
            ...queueContext,
            queueStates: res.data.result,
          });
          setloading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  /* for model open */
  const handleOpen = () => {
    Setmodel({ ...modelState, open: true, isEdit: false });
  };
  /* for model close */
  const handleClose = () =>
    Setmodel({
      ...modelState,
      states: "",
      bgcolor: "",
      icon: "",
      textcolor: "",
      // idmrole: [],
      isEdit: false,
      open: false,
    });

  /* on Edit Button Clicked */
  const onEditClicked = (data) => {
    // console.log(data);
    Setmodel({
      ...modelState,
      open: true,
      isEdit: true,
      statesid: data.statesid,
      states: data.states,
      bgcolor: data.bgcolor,
      icon: data.icon,
      textcolor: data.textcolor,
      // idmrole: data.idmrole,
      _key: data._key,
      _id: data._id,
      existingState: data.states,
    });
  };

  const addActionDrawer = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <DrawerAddAction
          getStatesData={getStatesData}
          data={data}
          onDrawerClosed={onDrawerClosed}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };
  const addActionDrawerEdit = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <DrawerAddAction
          getStatesData={getStatesData}
          data={data}
          onDrawerClosed={onDrawerClosed}
        />
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  /* const actionAdd = (id, value) => {
        let Action = stateData.map(l =>
            l.statesid === id ? Object.assign(l, { action: value }) : l
        )

        setStatedata(Action)
        props.updateState("StateAndAction", Action)
    } */

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const getStatemappedWithScenario = async (id) => {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "queuescenariostatemapping",
      filter: `queuescenariostatemapping.queueid=='${props.queue}' && queuescenariostatemapping.activestatus==true`,
      return_fields: `First(For doc in queuescenariostatemapping.states filter doc._id=='${id}' return MERGE(doc,{scenarioname:document(queuescenariostatemapping.scenarioid).scenarioname}))`,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    const res = await axios(config);

    return res?.data?.result;
  };

  const onDialogOpen = async (data) => {
    debugger;
    let state = await getStatemappedWithScenario(data?._id);
    let list = state.filter((l) => l !== null);
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title:
        list?.length > 0
          ? "You are about to delete a state that configured with scenario's!"
          : "Are you sure you want to delete the state?",
      body: list?.length > 0 ? <DialogMappedState list={list} /> : "",
      positiveBtn: "Confirm",
      disablePositiveBtn: list?.length > 0 ? true : false,
      negativeBtn: "Cancel",
      onOk: () => onDeletedClicked(data?._key),
    });
  };

  const onDeletedClicked = (_key) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    statesSoftDelete(_key);
  };
  const statesSoftDelete = async (_key) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestates",
        filter: `queuestates._key == '${_key}'`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then(async (res) => {
        // if (res) {
        // }
        let state_Id = res?.data?.Result[0]?.doc?._id;
        await updateRepoMappedInScenario(state_Id, "", "softDelete");
        await getStatesData();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "State Deleted Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeState = (key, value) => {
    if (key === "states") {
      Setmodel({ ...modelState, [key]: value });
    } else {
      Setmodel({ ...modelState, [key]: value });
    }
  };

  const KeyPress = (e) => {
    if (e.key === "Enter") {
      upsertstateQueue(modelState);
    }
  };

  const onSaveClicked = () => {
    if (modelState?.states?.trim().length === 0) {
      seterr(true);
    } else {
      let stateCheck = stateData.filter(
        (list) => list.states.toLowerCase() === modelState.states.toLowerCase()
      );
      if (stateCheck.length !== 0) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "State Name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        upsertstateQueue(modelState);
        // console.log(modelState)
      }

      seterr(false);
    }
  };

  const upsertstateQueue = async (modelState) => {
    try {
      backdropContext.setBackDrop({
        ...backdropContext,
        open: true,
        message: "Adding State...",
      });
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuestates",
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queueid: modelState.queueid,
            statesid: "",
            states: modelState.states,
            bgcolor: modelState.bgcolor,
            icon: modelState.icon,
            textcolor: modelState.textcolor,
            // idmrole: modelState.idmrole,
            version: modelState.version,
            active: modelState.active,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            // setStatedata(res.data.result);
            // console.log(res.data.result);
            getStatesData();
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "State added successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            handleClose();
            backdropContext.setBackDrop({
              ...backdropContext,
              open: false,
            });
          } else if (res.data.Code === 1111) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res?.data?.error ?? "Something Went Wrong"}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            backdropContext.setBackDrop({
              ...backdropContext,
              open: false,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            backdropContext.setBackDrop({
              ...backdropContext,
              open: false,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateClicked = async (index) => {
    // debugger;
    if (modelState?.states?.trim().length === 0) {
      seterr(true);
    } else {
      let stateCheck =
        modelState?.states === modelState?.existingState
          ? true
          : stateData.filter(
              (list) =>
                list.states.toLowerCase() === modelState.states.toLowerCase()
            ).length > 0
          ? false
          : true;

      if (stateCheck) {
        backdropContext.setBackDrop({
          ...backdropContext,
          open: true,
          message: "Updating State...",
        });
        await updateStatecall(modelState);
        if (modelState?.states !== modelState?.existingState) {
          await updateRepoMappedInScenario(
            modelState?._id,
            modelState?.states,
            "update"
          );
        }
        await updateScenarioStateMapping(modelState?.queueid, modelState);

        seterr(false);
        handleClose();
        backdropContext.setBackDrop({
          ...backdropContext,
          open: false,
        });
      } else {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "State name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        seterr(false);
      }
    }
  };

  const updateStatecall = async (modelState) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuestates",
          filter: {
            _key: `${modelState._key}`,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            queueid: modelState.queueid,
            statesid: modelState.statesid,
            states: modelState.states,
            bgcolor: modelState.bgcolor,
            icon: modelState.icon,
            textcolor: modelState.textcolor,
            // idmrole: modelState.idmrole,
            version: modelState.version,
            active: modelState.active,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            // setStatedata(res.data.result);
            // console.log(res.data.result);
            getStatesData();
            handleClose();
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "State Updated Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else if (res.data.Code === 1111) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res?.data?.error ?? "Something Went Wrong"}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getState = (state, newState, id) => {
    // console.log("state", state, newState,id);

    let list = state?.map((g) =>
      g?._id === id
        ? {
            ...g,
            states: newState?.states,
            bgcolor: newState?.bgcolor,
            icon: newState?.icon,
            textcolor: newState?.textcolor,
          }
        : g
    );
    return list;
  };

  const updateWorkflow = (wflow, newState, id) => {
    let wflw = wflow?.map((l) =>
      l?.data?.value?.status?._id === id
        ? {
            ...l,
            data: {
              ...l?.data,
              ...(l?.data?.text && {
                text:
                  l?.data?.actionState === "true"
                    ? l?.data?.text
                    : newState?.states,
              }),
              value: {
                status: {
                  ...l?.data?.value?.status,
                  states: newState?.states,
                  bgcolor: newState?.bgcolor,
                  icon: newState?.icon,
                  textcolor: newState?.textcolor,
                },
              },
            },
          }
        : l
    );
    return wflw;
  };

  const updateScenarioStateMapping = async (queueid, state) => {
    try {
      debugger;
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.queueid=='${queueid}' && queuescenariostatemapping.activestatus==true`,
        return_fields: "queuescenariostatemapping",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      const res = await axios(config);

      let mappedScenario = res?.data?.result;

      if (mappedScenario?.length > 0) {
        let mappedNewWithState = mappedScenario?.map((l) => {
          let list = {
            ...l,
            states: getState(l?.states, state, state?._id),
            workflowdiagram: updateWorkflow(
              l.workflowdiagram,
              state,
              state?._id
            ),
          };
          return list;
        });

        let responeList = mappedNewWithState.map(
          async (l) => await scenarioStateMappingUpdate(l)
        );

        return responeList;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBlur = () => setFocused(false);

  return (
    <>
      <Paper className={classes.papers} elevation={0}>
        <Box className={classes.Header}>
          <Typography variant="body1">States & Actions</Typography>
          <Button
            id={"Add State Button"}
            onClick={() => handleOpen()}
            variant="text"
            // style={{ color: "#3495FF" }}
            className={classes.Button}
          >
            {"+ Add State"}
          </Button>
        </Box>
        <Divider />
        <div className={classes.content}>
          {stateData?.length !== 0 ? (
            <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
              <Table
                size="small"
                aria-label="customized table"
                className={classes.Table}
              >
                <TableHead className={classes.TableHeader}>
                  <TableRow>
                    <StyledTableCell width={15} align="left">
                      <pre> </pre>
                    </StyledTableCell>
                    <StyledTableCell width={15} align="left">
                      S.No
                    </StyledTableCell>
                    <StyledTableCell align="left">States</StyledTableCell>
                    <StyledTableCell align="center">
                      &nbsp;&nbsp;
                    </StyledTableCell>
                    <StyledTableCell width={150} align="center">
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.TableBody}>
                  {stateData?.map((list, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell width={15} align="left">
                        <IconButton disableRipple={true}>
                          <DragIndicatorOutlinedIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {list?.states}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {list?.queuestatesaction?.length > 0 ? (
                          <>
                            <Chip
                              label={`${list?.queuestatesaction?.length} Action`}
                              variant="outlined"
                              sx={{ borderColor: "#3495FF" }}
                            />
                            <IconButton
                              id={"Actin Edit Icon"}
                              disableRipple={true}
                              onClick={() => addActionDrawerEdit(list)}
                            >
                              <EditOutlinedIcon color="primary" />
                            </IconButton>
                          </>
                        ) : (
                          <Button
                            id={"Add Action Button"}
                            onClick={() => addActionDrawer(list)}
                            variant="text"
                            style={{ color: "#3495FF" }}
                          >
                            + Add Action
                          </Button>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          id={"Edit Icon"}
                          disableRipple={true}
                          onClick={() => onEditClicked(list)}
                        >
                          <EditOutlinedIcon color="primary" />
                        </IconButton>
                        <IconButton
                          id={"Delete Icon"}
                          onClick={() => onDialogOpen(list)}
                          disableRipple={true}
                        >
                          <DeleteOutlineOutlinedIcon color="error" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : isloading ? (
            <LinearProgress />
          ) : (
            <Typography align="center">
              No States Added In This Queue Yet, Add New States!
            </Typography>
          )}
        </div>
        {/* Model */}
        <Modal
          open={modelState.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.style}>
            <Box className={classes.Header}>
              <Typography variant="body1">
                {modelState.isEdit ? "Update States" : "Add States"}
              </Typography>
            </Box>
            <Divider />
            <div style={{ padding: "24px 16px" }}>
              <Grid>
                <Grid item xs={12} lg={12} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"State Name"}
                    <IsRequired />
                  </Typography>
                  <BootstrapTooltip
                    title="If you are trying to update the state name,Please check with the scenarios state mapping, workflow and its rule configuration"
                    open={modelState.isEdit && focused}
                  >
                    <TextField
                      onChange={(e) => onChangeState("states", e.target.value)}
                      placeholder="Enter State Name"
                      fullWidth
                      autoComplete="off"
                      size="small"
                      name="states"
                      error={err}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onKeyPress={(e) => KeyPress(e)}
                      helperText={err === true ? Message.requiredMessage : ""}
                      value={modelState?.states}
                    />
                  </BootstrapTooltip>
                </Grid>
                <Grid item lg={12} md={12} xs={12} sx={{ p: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Background Color"}
                  </Typography>

                  <ColorTextBox
                    value={modelState?.bgcolor}
                    onHandleChange={(data) => onChangeState("bgcolor", data)}
                  />
                </Grid>

                <Grid item lg={12} md={12} xs={12} sx={{ p: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Text Color"}
                  </Typography>
                  <ColorTextBox
                    value={modelState?.textcolor}
                    onHandleChange={(data) => onChangeState("textcolor", data)}
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12} sx={{ p: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    State Icon
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FileUpload changeState={onChangeState} label={"icon"} />
                    {modelState?.icon && (
                      <img
                        width="20"
                        height="20"
                        style={{ marginLeft: "8px", marginTop: "2px" }}
                        src={modelState?.icon}
                        alt={"Scenario Icon"}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={classes.wrapperdiv}>
              <Button
                id={"Cancel Button"}
                onClick={() => handleClose()}
                variant="outlined"
              >
                {"Cancel"}
              </Button>
              {modelState.isEdit ? (
                <Button
                  id={"Update Button"}
                  onClick={() => onUpdateClicked(modelState?._key)}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Update"}
                </Button>
              ) : (
                <Button
                  id={"Add Button"}
                  onClick={() => onSaveClicked()}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Add"}
                </Button>
              )}
            </div>
          </Box>
          {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
        </Modal>
      </Paper>
    </>
  );
};

export const DialogMappedState = (props) => {
  const { list } = props;
  console.log("list", list);
  return (
    <>
      <Grid
        item
        xs={12}
        lg={12}
        sm={12}
        style={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          marginBottom: "8px",
        }}
      >
        <Grid item xs={5} lg={5} sm={5} style={{ alignSelf: "center" }}>
          <Typography variant="subtitle1">{"Scenario Name"}</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          lg={2}
          sm={2}
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <SwapHorizRoundedIcon color="primary" /> */}
        </Grid>
        <Grid item xs={5} lg={5} sm={5} style={{ alignSelf: "center" }}>
          <Typography variant="subtitle1">{"State"}</Typography>
        </Grid>
      </Grid>
      {list?.map((l) => (
        <Grid
          item
          xs={12}
          lg={12}
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid item xs={5} lg={5} sm={5} style={{ alignSelf: "center" }}>
            <Typography variant="body2">{l?.scenarioname}</Typography>
          </Grid>
          <Grid
            item
            xs={2}
            lg={2}
            sm={2}
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SwapHorizRoundedIcon color="primary" />
          </Grid>
          <Grid
            item
            xs={5}
            lg={5}
            sm={5}
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="body2">{l?.states}</Typography>
          </Grid>
        </Grid>
      ))}
    </>
  );
};
