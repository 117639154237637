import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  removeElements,
  addEdge,
  isNode,
  Background,
  Controls,
} from "react-flow-renderer";
import dagre from "dagre";
import CustomNodeComponentNew from "./customComponent";
import { v4 as uuidv4 } from "uuid";
import { Button, Modal, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { makeStyles } from "@mui/styles";
import { CreateRule } from "td-rule-builder";
import { performData } from "../../utils";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps } from "../../utils";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.table,
    // border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "8px",
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    backgroundColor: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
}));

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;
const position = { x: 0, y: 0 };
// const edgeType = 'smoothstep';

// -----Function for Vertical Alignment----------------------------

const getLayoutedElements = (elements, direction = "TB") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      el.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
    }

    return el;
  });
};

const BasicFlow = (props) => {
  // debugger;
  const classes = useStyles();
  const [elements, setElements] = useState(
    props?.scenarioStatemap?.workflowdiagram?.length > 0
      ? props?.scenarioStatemap?.workflowdiagram?.map((val) => {
          if (val.arrowHeadType) {
            return val;
          } else if (val.id === "6") {
            return {
              ...val,
              data: {
                ...val.data,
                secondLine: props.currentScenarios.scenarioname,
              },
            };
          } else {
            if (val.data.button === true) {
              return {
                ...val,
                data: {
                  ...val.data,
                  delbutton: true,
                  onclick: (data) => createEditRule(data),
                  onDeleteClicked: (data) => handleDelete(data),
                },
              };
            } else if (val.data.text === "Start") {
              return {
                ...val,
                data: {
                  ...val.data,
                  onclick: (data) => handleClick(data),
                },
              };
            } else if (val.data.button === false) {
              return {
                ...val,
                data: {
                  ...val.data,
                  onclick: (data) => handleClick(data),
                },
              };
            } else if (val.data.default === true) {
              return {
                ...val,
                data: {
                  ...val.data,
                  onclick: (data) => handleClick(data),
                },
              };
            } else {
              return {
                ...val,
                data: {
                  ...val.data,
                  delbutton: true,
                  onclick: (data) => handleClick(data),
                  onDeleteClicked: (data) => handleDelete(data),
                },
              };
            }
          }
        })
      : [
          {
            id: "6",
            type: "special",
            position: { x: 720, y: 100 },
            data: {
              text: "Start",
              secondLine: props.currentScenarios.scenarioname,
              onclick: (data) => handleClick(data),
              id: "6",
              startNode: "true",
              button: "true",
              btmHandle: "true",
              heading: true,
            },
          },
        ]
  );

  const [deldata, setDeldata] = React.useState();
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  const [value, setValue] = useState({});
  const [actionvalues, setActionvalues] = useState([]);
  // const [isId,setId]=React.useState({})
  const [nodeid, setNodeid] = useState();
  //   const [rule,setRule] = useState();
  const yPos = useRef(0);
  // const [ruleid, setRuleid] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  // const [openRule, setOpenRule] = React.useState(false);
  const [openRule, setOpenRule] = React.useState({
    open: false,
    dataid: "",
    ruleid: "",
  });
  const [statesaction, setstatesaction] = React.useState({
    open: false,
    status: {},
  });
  const dialogContext = React.useContext(DialogContext);
  const alertContext = React.useContext(AlertContext);
  const [error, setError] = React.useState({});
  const helperText = "please fill the required field";
  const onSaveClicked = (data) => {
    debugger;
    if (!data) {
      handleCloseRule();
    } else {
      let ruleid = data.Result[0].properties.doc._id;
      // console.log([...workFlow, ruleid]);

      /* let isarrayCheckDuplicate = workFlow.find((l) =>
        l === ruleid ? true : false
      ); */
      let isarrayCheckDuplicate =
        props?.scenarioStatemap?.workflow?.includes(ruleid);

      if (isarrayCheckDuplicate) {
        console.log("duplicate");
        handleCloseRule();
      } else {
        console.log("passes");

        // setworkFlow([...workFlow, ruleid]);
        // let workflowIdList = props?.scenarioStatemap?.workflow;
        // workflowIdList.push(ruleid);
        props?.updateScenarios(
          "workflow",
          props?.scenarioStatemap?.workflow.push(ruleid)
        );
        editActionrule(ruleid);
      }
    }
  };

  const editActionrule = (ruleid) => {
    let listElement = elements.map((l) =>
      l.id === openRule.dataid
        ? {
            ...l,
            data: { ...l.data, ruleid: ruleid },
          }
        : l
    );
    props.updateScenarios("workflowdiagram", listElement);
    setElements(listElement);
    handleCloseRule();
    // props?.updateScenarios("workflow", workFlow);
    // console.log("listElement", listElement);
  };

  // ------------------Callback function for Vertical alignment of Nodes ---------------------------------

  const onLayout = useCallback(
    (direction) => {
      const layoutedElements = getLayoutedElements(elements, direction);
      // debugger;
      setElements(layoutedElements);
    },
    [elements]
  );

  // ------Modal------

  const handleClick = (data) => {
    debugger;
    if (data?.secondLine !== "Add action") {
      setOpen(true);
      setNodeid(data);
    } else {
      let statList = props.stateList.filter(
        (l) => l._id === data.value.status._id
      )[0];
      setstatesaction({
        open: true,
        status: statList,
      });
      setNodeid(data);
    }
  };

  const actionhandleClose = () => {
    setstatesaction({
      open: false,
      status: {},
    });
  };

  //------Rule Builder Config------
  const createEditRule = (data) => {
    debugger;
    /* if (!data?.ruleid) {
      setOpenRule({
        open: true,
        dataid: data.id,
        gokul: "edit",
        // ruleid: "null",
      });
      setRuleid(data?.ruleid);
    } else {
      setOpenRule({
        open: true,
        dataid: data.id,
        gokul: "gokul",
        // ruleid: data.ruleid,
      }); */
    // setRuleid("");
    setOpenRule({
      open: true,
      dataid: data.id,
      ruleid: data.ruleid,
    });

    // console.log("rule open");
  };

  // ---------Initial Node Declaration ----------------

  React.useEffect(() => {
    // debugger;
    let defaultobj = props?.stateList?.find((val) => val.default === true);
    if (!props?.scenarioStatemap?.workflowdiagram?.length > 0) {
      if (defaultobj) {
        let obj = {};
        let uniqueid = uuidv4();
        obj.id = uniqueid;
        obj.position = position;
        obj.type = "special";
        obj.data = {
          text: defaultobj?.states,
          id: uniqueid,
          value: { status: defaultobj },
          onclick: (data) => handleClick(data),
          waitingNode: "true",
          handle: "true",
          btmHandle: "true",
          button: false,
          default: true,
        };

        // debugger;
        let action = {};
        let uniqueid2 = uuidv4();
        action.id = uniqueid2;
        action.position = position;
        action.type = "special";
        action.data = {
          secondLine: "Add action",
          id: uniqueid2,
          value: { status: defaultobj },
          onclick: (data) => handleClick(data),
          button: "true",
          handle: "true",
          btmHandle: "true",
          secaddBtn: "true",
          actionNode: "true",
          default: true,
        };

        // -----------JSON for Edge from Starting Node-------

        let startLink = {};
        let startlinkid = uuidv4();
        startLink.id = startlinkid;
        startLink.target = uniqueid;
        startLink.type = "step";
        startLink.arrowHeadType = "arrow";
        startLink.style = { stroke: "#007AFF" };
        startLink.source = "6";

        let link = {};
        let linkid = uuidv4();
        link.id = linkid;
        link.source = uniqueid;
        link.target = uniqueid2;
        link.type = "step";
        link.arrowHeadType = "arrow";
        link.style = { stroke: "#007AFF" };

        setElements([...elements, obj, action, startLink, link]);
        props.updateScenarios("workflowdiagram", [
          ...elements,
          obj,
          action,
          startLink,
          link,
        ]);
      }
    }

    //  onLayout('TB')
    // eslint-disable-next-line
  }, []);

  // ----------- Declaring Custom node-------

  const nodeTypes = {
    special: CustomNodeComponentNew,
  };

  const inputChange = (name, event, isState) => {
    debugger;
    error[name] = false;
    setError({ ...error });
    value[name] = event;
    setValue({ ...value });
    if (isState) {
      // let stateList = props.stateList;
      setActionvalues(event?.action);
      // setActionvalues(event.status)
    }
  };

  const inputactionChange = (name, event) => {
    // debugger;
    error[name] = false;
    setError({ ...error });
    // statesaction[name] = event;
    setstatesaction({ ...statesaction, [name]: event });
  };

  /* const setruletoworkflowdiagram = (elementid) => {
    let list = elements.map((l) => l);
    console.log(list);
  }; */

  const handleDelete = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this mapped flow?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => confirmDelete(data),
    });
  };

  const confirmDelete = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    setDeldata(data);
  };

  React.useEffect(() => {
    if (deldata) {
      deleteNode(deldata);
    }
    //eslint-disable-next-line
  }, [deldata]);

  const deleteNode = (data) => {
    // const list = elements.filter((l) => l.id !== data.id);
    /* const list = props?.scenarioStatemap?.workflowdiagram.filter(
      (l) => l.id !== data.id && l.target !== data.id && l.source !== data.id
    ); */
    const list = elements.filter(
      (l) => l.id !== data.id && l.target !== data.id && l.source !== data.id
    );
    props.updateScenarios("workflowdiagram", list);
    setElements(list);
    alertContext.setSnack({
      ...alertContext,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Mapped value deleted successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  const validDuplicate = (value, secondLine) => {
    debugger;
    if (secondLine === "Add state") {
      return true;
    } else {
      // let action = value.action;
      let duplicateFilter = elements.filter(
        (l) =>
          l?.data?.value?.status?._id === value?.status?._id &&
          l?.data?.text === value?.action?.action
      );
      if (duplicateFilter.length > 0) {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: "This State's Action Already Mapped",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return false;
      } else {
        return true;
      }
    }
  };

  const OnSave = (value) => {
    debugger;
    let error = ["status", "action"];
    if (
      nodeid?.secondLine === "Add state" ||
      nodeid?.secondLine === props.ScenariosList.scenarioname
    ) {
      error = ["status"];
    } else {
      error = ["action"];
    }
    let isError;

    error.forEach((v) => {
      if (!value[v]) {
        error[v] = true;
        isError = true;
        setError({ ...error });
      }
    });
    // let ismultipleaction = validDuplicate(value, nodeid?.secondLine);

    // console.log(ismultipleaction);
    if (!isError && validDuplicate(value, nodeid?.secondLine)) {
      yPos.current += 150;
      if (value.status.end === false) {
        let obj = {};
        let uniqueid = uuidv4();
        obj.id = uniqueid;
        obj.position = position;
        obj.type = "special";
        if (value.action) {
          obj.data = {
            text: value.action.action,
            id: uniqueid,
            value: value,
            // onclick: (data) => handleClick(data),
            onclick: (data) => createEditRule(data),
            actionState: "true",
            handle: "true",
            btmHandle: "true",
            button: true,
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        } else {
          obj.data = {
            text: value.status.states,
            id: uniqueid,
            value: value,
            onclick: (data) => handleClick(data),
            waitingNode: "true",
            handle: "true",
            btmHandle: "true",
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        }
        // debugger;
        let action = {};
        let uniqueid2 = uuidv4();
        action.id = uniqueid2;
        action.position = position;
        action.type = "special";
        if (value.action) {
          action.data = {
            secondLine: "Add state",
            id: uniqueid2,
            value: value,
            onclick: (data) => handleClick(data),
            button: "true",
            handle: "true",
            btmHandle: "true",
            secaddBtn: "true",
            actionNode: "true",
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        } else {
          action.data = {
            secondLine: "Add action",
            id: uniqueid2,
            value: value,
            onclick: (data) => handleClick(data),
            button: "true",
            handle: "true",
            btmHandle: "true",
            secaddBtn: "true",
            actionNode: "true",
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        }
        // -----------JSON for Edge from Starting Node-------

        let startLink = {};
        let startlinkid = uuidv4();
        startLink.id = startlinkid;
        startLink.target = uniqueid;
        startLink.type = "step";
        startLink.arrowHeadType = "arrow";
        startLink.style = { stroke: "#007AFF" };
        if (nodeid.text === "start") {
          startLink.source = "6";
        } else {
          startLink.source = JSON.parse(JSON.stringify(nodeid.id));
        }

        // ----------Edge JSON ----------------

        let link = {};
        let linkid = uuidv4();
        link.id = linkid;
        link.source = uniqueid;
        link.target = uniqueid2;
        link.type = "step";
        link.arrowHeadType = "arrow";
        link.style = { stroke: "#007AFF" };
        setElements([...elements, obj, action, startLink, link]);
        props.updateScenarios("workflowdiagram", [
          ...elements,
          obj,
          action,
          startLink,
          link,
        ]);
        handleClose();

        /*  if (value.action) {
          setOpenRule(true);
        } else {
          setOpenRule(false);
        } */

        // setruletoworkflowdiagram(obj.data.id);
        setValue({});
        setActionvalues();
        actionhandleClose();
        // onLayout('TB')
      } else {
        let obj = {};
        let resultid = uuidv4();
        obj.id = resultid;
        obj.position = position;
        obj.type = "special";
        // obj.data = { text: value.action.action, id: resultid, value: { value }, onclick: (data) => handleClick(data), resultNode: "true", handle: "true", }
        if (value.action) {
          obj.data = {
            text: value.action.action,
            id: resultid,
            value: value,
            onclick: (data) => handleClick(data),
            resultNode: "true",
            handle: "true",
            btmHandle: "true",
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        } else {
          obj.data = {
            text: value.status.states,
            id: resultid,
            value: value,
            onclick: (data) => handleClick(data),
            resultNode: "true",
            handle: "true",
            btmHandle: "true",
            delbutton: true,
            onDeleteClicked: (data) => handleDelete(data),
          };
        }
        // Edge JSON---------------------------

        let link = {};
        let linkid = uuidv4();
        link.id = linkid;
        link.source = nodeid?.id;
        link.target = resultid;
        link.type = "step";
        link.arrowHeadType = "arrow";
        link.style = { stroke: "#007AFF" };
        setElements([...elements, obj, link]);
        props.updateScenarios("workflowdiagram", [...elements, obj, link]);

        setNodeid("");
        handleClose();
        // setOpenRule(true);
        setValue({});
        setActionvalues();
        actionhandleClose();
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseRule = () => {
    setOpenRule({ open: false, dataid: "" });
  };

  /* const trialClose = () => {
    // console.log("close rule ")
    setOpenRule(false);
  } */
  const onresetClick = () => {
    debugger;
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to restore this workflow?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => confirmReset(),
    });

    // console.log("reset");
  };

  const confirmReset = () => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    let defaultobj = props?.stateList?.find((val) => val.default === true);

    if (defaultobj) {
      let obj = {};
      let uniqueid = uuidv4();
      obj.id = uniqueid;
      obj.position = position;
      obj.type = "special";
      obj.data = {
        text: defaultobj?.states,
        id: uniqueid,
        value: { status: defaultobj },
        onclick: (data) => handleClick(data),
        waitingNode: "true",
        handle: "true",
        btmHandle: "true",
        button: false,
        default: true,
      };

      // debugger;
      let action = {};
      let uniqueid2 = uuidv4();
      action.id = uniqueid2;
      action.position = position;
      action.type = "special";
      action.data = {
        secondLine: "Add action",
        id: uniqueid2,
        value: { status: defaultobj },
        onclick: (data) => handleClick(data),
        button: "true",
        handle: "true",
        btmHandle: "true",
        secaddBtn: "true",
        actionNode: "true",
        default: true,
      };

      // -----------JSON for Edge from Starting Node-------

      let startLink = {};
      let startlinkid = uuidv4();
      startLink.id = startlinkid;
      startLink.target = uniqueid;
      startLink.type = "step";
      startLink.arrowHeadType = "arrow";
      startLink.style = { stroke: "#007AFF" };
      startLink.source = "6";

      let link = {};
      let linkid = uuidv4();
      link.id = linkid;
      link.source = uniqueid;
      link.target = uniqueid2;
      link.type = "step";
      link.arrowHeadType = "arrow";
      link.style = { stroke: "#007AFF" };

      setElements([
        {
          id: "6",
          type: "special",
          position: { x: 720, y: 100 },
          data: {
            text: "Start",
            secondLine: props?.currentScenarios?.scenarioname,
            onclick: (data) => handleClick(data),
            id: "6",
            startNode: true,
            button: "true",
            btmHandle: "true",
            heading: true,
          },
        },
        obj,
        action,
        startLink,
        link,
      ]);

      //  setElements([obj, action, startLink, link]);
      props.updateScenarios("workflowdiagram", [
        {
          id: "6",
          type: "special",
          position: { x: 720, y: 100 },
          data: {
            text: "Start",
            secondLine: props?.currentScenarios?.scenarioname,
            onclick: (data) => handleClick(data),
            id: "6",
            startNode: true,
            button: "true",
            btmHandle: "true",
            heading: true,
          },
        },
        obj,
        action,
        startLink,
        link,
      ]);
    }
  };

  // Modal Body --------------------------------
  // console.log("props?.stateList", props?.stateList)
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Add an Action to the Node</h2>
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="action add list"
          options={props?.stateList ?? []}
          getOptionLabel={(option) => option[props?.optionKey ?? "label"]}
          value={value.status}
          style={{ width: 300, marginBottom: 20 }}
          onChange={(e, value) => {
            inputChange("status", value, true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="States"
              variant="outlined"
              helperText={error.status ? helperText : ""}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              error={error.status}
            />
          )}
        />
      </FormControl>
      <br />
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="action add list"
          options={actionvalues ?? []}
          getOptionLabel={(option) => option.action}
          value={value.action}
          style={{ width: 300 }}
          disabled={
            nodeid?.secondLine === "Add state" ||
            nodeid?.secondLine === props.ScenariosList.scenarioname
              ? true
              : false
          }
          // disabled={false}
          onChange={(e, value) => {
            inputChange("action", value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Actions"
              variant="outlined"
              helperText={error.action ? helperText : ""}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              error={error.action}
            />
          )}
        />
      </FormControl>
      <br />
      <div style={{ marginTop: "20px", display: "flex" }}>
        <Button onClick={() => OnSave(value)}>Save</Button>
        <Button onClick={() => handleClose()} sx={{ marginLeft: "8px" }}>
          Cancel
        </Button>
      </div>
    </div>
  );

  const body2 = (
    <div style={modalStyle} className={classes.paper}>
      <h2 id="simple-modal-title">Add an Action to the Node</h2>
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="action add list"
          options={[]}
          // getOptionLabel={(option) => option[props?.optionKey ?? "label"]}
          // value={statesaction?.status?.value?.status?.states ?? "jok"}
          defaultValue={statesaction?.status?.states ?? ""}
          style={{ width: 300, marginBottom: 20 }}
          disabled={true}
          onChange={(e, value) => {
            inputChange("status", value, true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select States"
              variant="outlined"
              helperText={error.status ? helperText : ""}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              error={error.status}
            />
          )}
        />
      </FormControl>
      <br />
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="action add list"
          options={statesaction?.status?.action ?? []}
          getOptionLabel={(option) => option.action}
          value={value.action}
          style={{ width: 300 }}
          onChange={(e, value) => {
            inputactionChange("action", value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Actions"
              variant="outlined"
              helperText={error.action ? helperText : ""}
              FormHelperTextProps={{
                className: classes.helperText,
              }}
              error={error.action}
            />
          )}
        />
      </FormControl>
      <br />
      <div style={{ marginTop: "20px", display: "flex" }}>
        <Button onClick={() => OnSave(statesaction)}>Save</Button>

        <Button onClick={() => actionhandleClose()} sx={{ marginLeft: "8px" }}>
          Cancel
        </Button>
      </div>
    </div>
  );
  return (
    <>
      <div style={{ height: "calc(100% - 20px)", width: "100%" }}>
        <div style={{ height: "calc(100% - 40px)", width: "100%" }}>
          <ReactFlow
            elements={elements}
            nodeTypes={nodeTypes}
            onElementsRemove={onElementsRemove}
            onConnect={onConnect}
            // deleteKeyCode={46} /* 'delete'-key */
            // onElementClick={(event, element) =>onElementClick(event, element)}
          >
            <Background variant="dot" color="#81818a" />
            <Controls />
          </ReactFlow>
        </div>

        {/* Button to Align Nodes ------------------------ */}

        <Button
          variant="contained"
          className={classes.Button}
          onClick={() => onLayout("TB")}
        >
          Align layout
        </Button>
        <Button
          variant="contained"
          onClick={() => onresetClick()}
          className={classes.Button}
          sx={{ marginLeft: "8px" }}
        >
          Reset
        </Button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Modal
        open={statesaction.open}
        onClose={actionhandleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body2}
      </Modal>

      <Modal
        open={openRule.open}
        onClose={handleCloseRule}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className={classes.style}>
          <CreateRule
            inputSchema={props?.inputSchemaList}
            // inputSchema={props?.queueList?.inputschema[0].inputschema}

            handleClose={(data) => {
              onSaveClicked(data);
            }}
            editId={openRule.ruleid}
            open={true}
            performvalueData={{
              // stateList: stateList,
              stateList: props.scenarioStatemap.states,
              // scenarioList: scenarioList,
              scenarioList: props?.ScenariosList,
              queueList: JSON.parse(sessionStorage.getItem("queueList")),
              userRole: JSON.parse(sessionStorage.getItem("userAssignee")),
              userGroup: JSON.parse(sessionStorage.getItem("userGroup")),
            }}
            performKeyData={performData}
            dbName={`${process.env.REACT_APP_DB}`}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BasicFlow;
