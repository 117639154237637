import React, { useState } from "react";
import { Button, Divider, Typography, Box, Checkbox } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import {
  getRepoid,
  UpdateRepo,
  UpdateRepoStatus,
  upsertRepo,
} from "../../utils/apiCall";
import { makeStyles } from "@mui/styles";
// import { ProjectInfoContext } from "../../contexts";
const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.table,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 24px 16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 107px)",
    overflowY: "auto",
    background: theme.palette.background.common,
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.common,
    borderTop: " 1px solid #e0e0e0",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
    background: theme.palette.background.table,
  },
  content: {
    // padding: "16px",
    height: "52px",
    // overflow: "auto",
    background: theme.palette.background.common,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  Checkbox: {
    color: theme.palette.text.primary,
  },
}));
export function ScenarioState(props) {
  const classes = useStyles();
  const [lists, setList] = useState([]);
  const [state, SetState] = useState([]);
  // const [isAdd, setAdd] = useState(false);
  // const projectinfo = React.useContext(ProjectInfoContext);
  React.useEffect(() => {
    getStateData();
    // readStateData()
    // eslint-disable-next-line
  }, []);

  const getStateData = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuestates",
        filter: `queuestates.queueid=='${props.qid}' &&queuestates.active==true && queuestates.activestatus==true`,
        return_fields:
          "{_id:queuestates._id,states:queuestates.states,stateid:queuestates.statesid}",
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          let _l = res.data.result;
          // debugger;
          if (props.isEdit) {
            for (let i = 0; i < _l.length; i++) {
              for (let j = 0; j < props.state.length; j++) {
                if (_l[i]?.stateid === props.state[j]?.stateid) {
                  Object.assign(_l[i], {
                    isChecked: true,
                    action: props?.state[j]?.action ?? [],
                    possiblestates: props?.state[j]?.possiblestates ?? [],
                    default: props?.state[j]?.default ?? false,
                    end: props?.state[j]?.end ?? false,
                    sequenceno: props?.state[j]?.sequenceno,
                  });
                } else if (_l[i].isChecked !== true) {
                  Object.assign(_l[i], {
                    isChecked: false,
                    action: [],
                    possiblestates: [],
                    default: false,
                    end: false,
                    sequenceno: props?.state[j]?.sequenceno,
                  });
                }
              }
            }

            _l.sort((a, b) => a.sequenceno - b.sequenceno);

            setList(_l);
            SetState(_l);
            // console.log("l", _l);
          } else {
            let update_list = _l.map((item) =>
              Object.assign(item, {
                isChecked: false,
                action: [],
                possiblestates: [],
                default: false,
                end: false,
              })
            );
            setList(update_list);
            SetState(update_list);
          }

          // console.log(update_l);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //   const onCheckAllClicked = (event) => {
  //     let checkedList = lists;
  //     checkedList.map(list => { ...item, isChecked: e.target.checked };
  //   setList(checkedList);
  //   console.log("All", checkedList);
  // };

  const onChangetrue = (e, id) => {
    let checkedList = lists.map((l, i) =>
      // id === i ? Object.assign(l, { isChecked: e.target.checked }) : l
      {
        if (id === i) {
          const rep = getRepoid(props.scenariosid);
          rep.then((result) => {
            if (result?.length > 0) {
              getCurrantRepo(
                e.target.checked,
                l._id, //uniqueid for state
                "Task and Document", //rep_type
                l.states, //rep_name
                result[0].rep_id
              );
            }
          });

          return Object.assign(l, { isChecked: e.target.checked });
        } else {
          return l;
        }
      }
    );
    setList(checkedList);
    let filtered = checkedList.filter((l) => l.isChecked === true);
    // setAdd(false);
    SetState(filtered);
  };

  const onAddClicked = () => {
    let removeisCheck = state.map((l) => {
      delete l.isChecked;
      return l;
    });

    let addingSequenceID = removeisCheck.map((l, index) => {
      Object.assign(l, { sequenceno: index + 1 });
      return l;
    });

    props.listUpdate(addingSequenceID);
    props.onDrawerClosed();
  };

  const getCurrantRepo = async (
    isChecked,
    uniqueid,
    reptype,
    repname,
    parentrep
  ) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: `IDM_Repository.parent_rep=='${parentrep}'&& IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
        return_fields: "IDM_Repository",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            if (isChecked && res.data.result.length === 0) {
              upsertRepo(reptype, repname, parentrep, uniqueid);
            } else if (isChecked === false) {
              let data = res?.data?.result[0];
              if (data?._key) {
                UpdateRepoStatus(data._key, isChecked);
              }
            } else {
              if (isChecked && res.data.result.length > 0) {
                let data = res?.data?.result[0];

                UpdateRepo(data._key, repname, parentrep, true);
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  return (
    <div style={{ height: "100vh" }}>
      <Box className={classes.Header2}>
        <Typography variant="h6">{props.title}</Typography>
      </Box>
      <Divider />
      <div className={classes.actionList}>
        {/* <Grid container>
        <Grid item xs={12} sx={{ mt: 1 }}>

          <TextField

            id="outlined-name"

            // label="Enter State Name"

            // name="statesName"

            // value={state?.isEditstateName ? state?.statesName : ""}

            // onChange={(e) => onChangeState(e)}

            placeholder="Search"

            fullWidth

            size='small'

            InputProps={{

              startAdornment: (

                <InputAdornment position="start">

                  <SearchIcon />

                </InputAdornment>

              ),

            }}

            margin='dense'

          // error={state?.errorStatesName}

          // helperText={state?.errorStatesName === true ? Message.requiredMessage : ""}

          />

        </Grid>

      </Grid> */}
        <List>
          {/*  <ListItem dense>
          <ListItemButton dense>
            <FormControlLabel control={<Checkbox />} label={"All"} />
          </ListItemButton>
        </ListItem> */}
          {lists?.map((list, index) => (
            <ListItem dense key={index}>
              <ListItemButton dense className={classes.actionListItem}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onChangetrue(e, index)}
                      checked={list.isChecked}
                      color="primary"
                      className={classes.Checkbox}

                      // value={list.}
                    />
                  }
                  label={list?.states}
                />

                {/*  <ListItemText primary={list?.stateName} /> */}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </div>
      {/* <Divider /> */}
      <div className={classes.content}>
        {/* <Divider /> */}
        <div className={classes.wrapperdiv2}>
          <Button
            id={"Cancel Button"}
            onClick={() => props.onDrawerClosed()}
            variant="outlined"
          >
            {"Cancel"}
          </Button>
          <Button
            id={"Add"}
            onClick={() => onAddClicked()}
            variant="contained"
            // disabled={isAdd}
            sx={{ ml: "16px" }}
            className={classes.Button}
          >
            {"Add"}
          </Button>
        </div>
      </div>
    </div>
  );
}
