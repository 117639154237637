import axios from "axios";
import { v4 as uuidv4 } from "uuid";

/* IDM Repositry insert call */

export const upsertRepo = async (reptype, repname, parent_rep, _id) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: {
          rep_id: uuidv4(),
          rep_type: reptype,
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          route_url: "",
          is_active: true,
          unique_id: _id,
          projectid: `${localStorage.getItem("projectId")}`,
          clientid: `${localStorage.getItem("clientId")}`,
          metadataid: `${localStorage.getItem("IDMmetaId")}`,
        },
      },
    ];
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    await axios(config)
      .then((res) => {
        if (res.data.Code === 201) {
          return res;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (error) {
    console.log(error);
  }
};

/* IDM Repositry update call */

export const UpdateRepo = async (_key, repname, parent_rep, active) => {
  try {
    /*  let data = active
      ? {
          // rep_id: _id,
          // rep_type: reptype,
          rep_name: repname,
          parent_rep: parent_rep ?? "",
          is_active: true,
          // route_url: "",
        }
      : {
          rep_name: repname,
          parent_rep: parent_rep ?? "",
        }; */
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: {
          _key: _key,
        },
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: {
          // rep_id: _id,
          // rep_type: reptype,
          rep_name: repname,
          // parent_rep: parent_rep ?? "",
          ...(active && { is_active: true }),
          // route_url: "",
        },
      },
    ];
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    await axios(config)
      .then((res) => {
        if (res.data.Code === 201) {
          return res.data.Result[0].properties.doc;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (error) {
    console.log(error);
  }
};

/* IDM Repositry active status call */

export const UpdateRepoStatus = async (_key, active) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: {
          _key: _key,
        },
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        doc: {
          is_active: active,
          activestatus: active,
        },
      },
    ];
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    await axios(config)
      .then((res) => {
        if (res.data.Code === 201) {
          return res.data.Result[0].properties.doc;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (error) {
    console.log(error);
  }
};

export const getRepoid = async (uniqueid) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "IDM_Repository",
      filter: `IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
      return_fields: "IDM_Repository",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    const res = await axios(config);
    // .then((res) => {
    //   if (res.data.Code === 201) {
    //
    //     // console.log(data.rep_id);
    //     // return data.rep_id;
    //   }
    //   // console.log(res.data.result);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
    const data = await res.data.result;
    return data;
  } catch (error) {}
};

export const getRepoIdStateMapping = async (parentUniqueId, uniqueid) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "IDM_Repository",
      filter: `IDM_Repository.parent_rep=='${parentUniqueId}'&&IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
      return_fields: "IDM_Repository",
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    const res = await axios(config);
    const data = await res.data.result;
    return data;
  } catch (error) {}
};

export const softDeleteRepo = async (_key) => {
  try {
    let params = {
      db_name: `${localStorage.getItem("ProjectDbname")}`,
      entity: "IDM_Repository",
      filter: `IDM_Repository._key=='${_key}'`,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_SOFTDELETE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    await axios(config).then((res) => {
      // console.log(res);
      return res;
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateRepoMappedInScenario = async (_id, newName, call) => {
  debugger;
  let res = await getRepoid(_id);
  if (res?.length > 0) {
    if (call === "update") {
      res.map(async (l) => await UpdateRepo(l?._key, newName, "", true));
    } else if (call === "softDelete") {
      res.map(async (l) => await softDeleteRepo(l?._key));
    }
  }
};

export const scenarioStateMappingUpdate = async (data) => {
  try {
    let params = [
      {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        is_metadata: true,
        metadataId: `${localStorage.getItem("metaId")}`,
        metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
        filter: {
          _key: `${data._key}`,
        },
        doc: {
          states: data.states,
          workflow: data.workflow,
          workflowdiagram: data.workflowdiagram,
        },
      },
    ];
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };
    const res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
  }
};
