import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TopNavBar } from "../components";
import { ThemeContext } from "../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  content: {
    width: "100%",
    height: "calc(100vh - 64px)",
    overflow: "auto",
    background: "#F2F3F6",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  ismicrocontent: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    background: "#F2F3F6",
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
  topNavbar: {},
}));

const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });
  const [state, setState] = React.useState(false);
  const themeContext = React.useContext(ThemeContext);
  React.useEffect(() => {
    // debugger;
    setState(JSON.parse(sessionStorage.getItem("isMicroFrontEnd")));
    themeContext.setTheme({
      ...themeContext,
      name: sessionStorage.getItem("theme"),
    });
    //eslint-disable-next-line
  }, [state]);

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      {!state && (
        <div className={classes.topNavbar}>
          <TopNavBar />
        </div>
      )}

      {/* Content */}
      <div className={state ? classes.ismicrocontent : classes.content}>
        <Component {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;

//For sidebar
// <div className={classes.sideNavbar}>
//   <SideNavBar />
// </div>
