import { Handle, Position } from "react-flow-renderer";
import settingsIcon from "../../assets/icons8-settings.svg";
import addICon from "../../assets/add-icon.svg";
import infoIcon from "../../assets/icons8-info.svg";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const startNode = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  border: "2px solid #FFD174",
  borderRadius: "8px",
  opacity: 1,
  width: "auto",
  textAlign: "center",
  padding: "8px 16px",
  color: "#000",
};
const waitingNode = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  border: "2px solid #92C6FF",
  borderRadius: "8px",
  opacity: 1,
  width: "auto",
  textAlign: "center",
  padding: "8px 16px",
  color: "#000",
};
const resultNode = {
  background: "#7CD900",
  color: "#FFFFFF",
  borderRadius: "8px",
  // opacity: 1,
  width: "auto",
  // height: "auto",
  padding: "8px 16px",
  textAlign: "center",
  // color: "#000",
};
const actionNode = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  borderRadius: "25px",
  width: "auto",
  // height: "40px",
  padding: "8px 16px",
  textAlign: "center",
  color: "#000",
};
const actionState = {
  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  border: "2px solid #C82AFF",
  borderRadius: "8px",
  width: "Auto",
  padding: "8px 16px",
  color: "#000",
};
const CustomNodeComponentNew = ({ data, isConnectable }) => {
  // console.log(data, "data")
  // console.log(isConnectable, "isConnectable")
  // debugger

  return (
    <div
      style={
        data.startNode
          ? startNode
          : data.waitingNode
          ? waitingNode
          : data.resultNode
          ? resultNode
          : data.actionNode
          ? actionNode
          : actionState
      }
    >
      {data?.handle && (
        <Handle
          type="target"
          position={Position.Top}
          style={{ borderRadius: 0 }}
        />
      )}
      {data?.text && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingLeft: "4px",
          }}
        >
          <div
            style={{
              fontFamily: "NunitoBold",
              fontWeight: data?.heading ? "600" : "400",
              textTransform: data?.heading ? "uppercase" : "capitalize",
            }}
          >
            {data.text}
          </div>
          {data?.infoButton && (
            <button
              style={{
                background: "#FFFFFF",
                border: "none",
                color: "#000",
              }}
            >
              <img src={infoIcon} alt={"Info Icon"} />
            </button>
          )}
          {data?.button && (
            <button
              onClick={() => data?.onclick(data)}
              style={{
                marginTop: "5px",
                background: "#FFFFFF",
                border: "none",
                color: "#000",
              }}
            >
              <img src={settingsIcon} alt={"Settings Icon"} />
            </button>
          )}
          {data?.delbutton && (
            <button
              onClick={() => data?.onDeleteClicked(data)}
              style={{
                marginTop: "6px",
                background: "rgba(0,0,0,0)",
                border: "none",
                padding: 0,
                color: "#000",
              }}
            >
              <DeleteOutlineOutlinedIcon
                color="error"
                style={{ fontSize: "1.2rem", background: "rgba(0,0,0,0)" }}
              />
            </button>
          )}
        </div>
      )}
      {/* Second Line --------------------------- */}
      {data?.secondLine && (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
            width: "auto",
          }}
        >
          <div
            onClick={() => data?.onclick(data)}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            {data?.secaddBtn && (
              <button
                style={{
                  marginTop: "4px",
                  background: "#FFFFFF",
                  border: "none",
                  color: "#000",
                }}
              >
                <img src={addICon} alt={"Add Icon"} />
              </button>
            )}
            <div style={{ fontFamily: "NunitoSemiBold" }}>
              {data.secondLine}
            </div>
          </div>

          {data?.secinfoButton && (
            <button
              style={{
                background: "#FFFFFF",
                border: "none",
                color: "#000",
              }}
            >
              <img src={infoIcon} alt={"Info Icon"} />
            </button>
          )}
          {data?.delbutton && (
            <button
              onClick={() => data?.onDeleteClicked(data)}
              style={{
                marginTop: "5px",
                background: "rgba(0,0,0,0)",
                border: "none",
                color: "#000",
              }}
            >
              {/* <img src={settingsIcon} alt={"Settings Icon"} /> */}
              <DeleteOutlineOutlinedIcon
                color="error"
                style={{ fontSize: "1.2rem", background: "rgba(0,0,0,0)" }}
              />
            </button>
          )}
        </div>
      )}

      {/* {data.settingsIcon && <div onClick={() => data?.onclick(data)}><button id={data?.id}  ><SettingsIcon /></button></div>} */}
      {/* {data.infoIcon && <div><button ><InfoIcon /></button></div>} */}

      {data?.btmHandle && (
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          style={{ borderRadius: 0 }}
        />
      )}
    </div>
  );
};
export default CustomNodeComponentNew;
