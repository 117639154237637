import { Box, Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { SubHeader, TabPanel } from "../../components";
// import { Routes } from "../../router/routes";
// import Addscenario from '../addScenario';
import QueueDetails from "./queueDetails";
import { StatesAndAction } from "./statesAndAction";
import { Scenarios } from "../Scenario/index";
// import { Addscenario } from '../Scenario/addScenario'
import axios from "axios";
import { styled } from "@mui/styles";
import { InputSchema } from "./inputSchema";
import { useHistory, useParams } from "react-router-dom";
import { AlertContext, QueueContext } from "../../contexts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AlertProps } from "../../utils";
// import { FactMaster } from "./factMaster";
import { UpdateRepo, upsertRepo } from "../../utils/apiCall";
import { QueueGroup } from "./queueGroup";
const useStyles = makeStyles((theme) => ({
  tabWrapper: {
    height: "calc(100% - 52px)",
    flexGrow: 1,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  tab: {
    width: "300px",
    padding: "12px",
    borderRight: "1px solid #DCDCDC",
    background: theme.palette.background.table,
    transition: "all .2s ease-in-out",

    "& .MuiTabs-flexContainer": {
      position: "relative",
      zIndex: "300",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "0 16px",
    },
  },
  indicator: {
    display: "inline",
    // background: "rgba(1,32,92,1)",
    height: "3px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: `${theme.sideBar.background.active}`,
  },
  microIndicator: {
    display: "inline",
    width: "100%",
    // background: "rgba(1,32,92,0.1)",
    backgroundColor: `${theme.sideBar.background.active}`,
    height: "48px",
    borderRadius: "4px",
  },
}));

const MyTab = styled(Tab)(({ theme }) => ({
  alignItems: "flex-start",
  transition: "all .4s ease-out",
  // color: `${theme.palette.text.primary}`,
  "&.Mui-selected": {
    // backgroundColor: `${theme.sideBar.background.active}`,
    borderRadius: "4px",
    color: `${theme.sideBar.text.active}`,
  },
}));

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export const AddCreateQueue = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const alertMessage = React.useContext(AlertContext);
  const [value, setValue] = React.useState(0);
  const { id } = useParams();
  const queueContext = React.useContext(QueueContext);
  const [isupdate, setUpdate] = React.useState(false);

  const pass = props?.configure ? window.atob(id?.slice(3)) : "";

  const [createQueue, setCreateQueue] = React.useState({
    queueid: "",
    queuename: "",
    msgqueuegroup: "",
    queuedescription: "",
    kafkatopicname: "",
    entrycriteria: [],
    inputschema: [],
    queuestatus: true,
    version: "",
    active: true,
    projectid: `${localStorage.getItem("projectId")}`,
    clientid: `${localStorage.getItem("clientId")}`,
    metedataid: `${localStorage.getItem("metaDataId")}`,
    idmid: "",
    groupdetails: [],
    // idmrole: [],
  });

  React.useEffect(() => {
    if (props?.configure) {
      getSingleRead(pass);
    }
    // eslint-disable-next-line
  }, [props?.configure]);

  const getSingleRead = async (pass) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queue",
        filter: `queue.queueid=='${pass}' && queue.active==true && queue.activestatus==true`,
        return_fields: "queue",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // console.log(res.data.result[0])
          setCreateQueue(res.data.result[0]);

          queueContext.setQueue({
            ...queueContext,
            queue: res.data.result[0],
          });
        })
        .catch((err) => {
          alert("Something Went Wrong");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onBackClicked = () => {
    history.goBack();
  };

  const onSaveClicked = () => {
    let isqueuename =
      createQueue?.queuename?.trim().length === 0 ? false : true;
    let isinputschema = createQueue?.inputschema?.length === 0 ? false : true;
    // let isidmid = createQueue?.idmid.trim().length === 0 ? false : true;
    // let isinputSchemaparse = JSON.parse(createQueue?.inputschema[0]?.inputschema) ? false : true
    if (isqueuename & isinputschema) {
      let queuelist = JSON.parse(sessionStorage.getItem("queueList"));
      const queueCheck = queuelist.filter(
        (l) => l.queuename.toLowerCase() === createQueue.queuename.toLowerCase()
      );
      if (queueCheck.length !== 0) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        upsertCreateQueue(createQueue);
      }
    } else {
      if (!isinputschema) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Input Schema is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!isqueuename) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      // else if (!isidmid) {
      //   alertMessage?.setSnack({
      //     ...alertMessage,
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: "IDM ID is Empty",
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //   });
      // }
    }
  };
  const upsertCreateQueue = async (createQueue) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queue",
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: createQueue,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          //queuename === createQueue?.queuename
          if (res.data.Code === 201) {
            let data = res.data.Result[0].properties.doc;
            history.goBack();
            upsertRepo("Task and Document", data.queuename, "", data._id);

            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Queue Added Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else if (res.data.Code === 1111) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res?.data?.error ?? "Something Went Wrong"}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }

          // console.log(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const updateState = (key, value) => {
    // let _ = { ...createQueue, [key]: value }
    if (key === "queuename") {
      setCreateQueue({ ...createQueue, [key]: value });
      setUpdate(true);
    } else {
      setCreateQueue({ ...createQueue, [key]: value });
    }
    // console.log({ ...createQueue, [key]: value })
  };

  const onUpdateClicked = () => {
    debugger;
    let isqueuename =
      createQueue?.queuename?.trim().length === 0 ? false : true;
    let isinputschema = createQueue?.inputschema?.length === 0 ? false : true;
    // let isidmid = createQueue?.idmid.trim().length === 0 ? false : true;
    if (isqueuename & isinputschema) {
      let queuelist = JSON.parse(sessionStorage.getItem("queueList"));
      const queneCheck = queuelist.filter(
        (l) => l.queuename.toLowerCase() === createQueue.queuename.toLowerCase()
      );
      if (isupdate && queneCheck.length > 0) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name already exists",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        UpdateCreatequeue(createQueue);
      }
      // UpdateCreatequeue(createQueue);
      //  console.log(createQueue);
    } else {
      if (!isinputschema) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Input Schema is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else if (!isqueuename) {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Queue Name is Empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
      //  else if (!isidmid) {
      //   alertMessage?.setSnack({
      //     ...alertMessage,
      //     open: true,
      //     severity: AlertProps.severity.error,
      //     msg: "IDM ID is Empty",
      //     vertical: AlertProps.vertical.top,
      //     horizontal: AlertProps.horizontal.center,
      //   });
      // }
    }
    // console.log(createQueue);
  };
  const UpdateCreatequeue = async (createQueue) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queue",
          filter: {
            _key: createQueue._key,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: createQueue,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            let data = res.data.Result[0].properties.doc;
            getSingleRead(pass);
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Queue Updated Successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            getCurrantRepo(
              data._id,
              "Task and Document",
              data.queuename,
              "",
              data._id
            );
          }
          // history.push(Routes.home)
          else if (res.data.Code === 1111) {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${res?.data?.error ?? "Something Went Wrong"}`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
          // console.log(res.data.result);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrantRepo = async (queueid, reptype, repname, parent_rep, _id) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: `IDM_Repository.unique_id=='${queueid}' && IDM_Repository.activestatus==true`,
        return_fields: "IDM_Repository",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            if (res.data.result.length === 0) {
              upsertRepo(reptype, repname, parent_rep, _id);
            } else {
              let data = res.data.result[0];
              UpdateRepo(data._key, repname, "", false);
            }
          }

          // console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };
  return (
    <>
      <SubHeader
        title={
          props?.configure
            ? `${queueContext?.queue?.queuename ?? ""}`
            : "Create Queue"
        }
        edit={props?.configure}
        button1="Cancel"
        button2="Save"
        button3="Update"
        onUpdateClicked={onUpdateClicked}
        onSaveClicked={onSaveClicked}
        onBackClicked={onBackClicked}
        value={value}
      />
      <Box className={classes.tabWrapper}>
        <Tabs
          orientation={matches ? "horizontal" : "vertical"}
          variant="scrollable"
          value={value}
          onChange={handleChange}
          className={classes.tab}
          TabIndicatorProps={
            matches
              ? {
                  style: {
                    display: "inline",
                    // background: "rgba(1,32,92,1)",
                    backgroundColor: theme.sideBar.background.active,
                    // color: `${theme.sideBar.text.active}`,
                    height: "3px",
                    borderRadius: "5px 5px 0px 0px",
                  },
                }
              : {
                  style: {
                    display: "inline",
                    width: "100%",
                    // background: "rgba(1,32,92,0.1)",
                    backgroundColor: `${theme.sideBar.background.active}40`,
                    transition: "all .2s ease-in-out",
                    // opacity: "0.3",
                    // color: `${theme.sideBar.text.active}`,
                    height: "48px",
                    borderRadius: "4px",
                    zIndex: 10,
                  },
                }
          }
          textColor="primary"
        >
          <MyTab disableRipple={true} label="Input Schema" {...a11yProps(0)} />
          <MyTab disableRipple={true} label="Queue Details" {...a11yProps(1)} />
          <MyTab
            disableRipple={true}
            disabled={!props?.configure}
            label="Group Queue"
            {...a11yProps(2)}
          />
          <MyTab
            disableRipple={true}
            disabled={!props?.configure}
            label={"State & Actions"}
            {...a11yProps(3)}
          />
          <MyTab
            disableRipple={true}
            disabled={!props?.configure}
            label="Scenarios"
            {...a11yProps(4)}
          />

          {/* <MyTab
            disableRipple={true}
            disabled={!props?.configure}
            label="Fact Master"
            {...a11yProps(4)}
          /> */}
        </Tabs>
        <TabPanel value={value} index={0}>
          {/* input schema Tab */}
          <InputSchema createQueue={createQueue} updateState={updateState} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          {/* Queue Details Tab */}
          <QueueDetails
            pass={pass}
            getSingleRead={getSingleRead}
            isconfig={!props?.configure}
            createQueue={createQueue}
            updateState={updateState}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* Fact Master Tab */}
          <QueueGroup
            isconfig={!props?.configure}
            createQueue={createQueue}
            updateState={updateState}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* State and Action Tab */}
          <StatesAndAction
            queue={createQueue?._id}
            createQueue={createQueue}
            updateState={updateState}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          {/* Scenarios Tab */}
          <Scenarios
            createQueue={createQueue}
            queueid={createQueue?._id}
            updateState={updateState}
          />
        </TabPanel>

        {/* <TabPanel value={value} index={5}>
           Fact Master Tab
          <FactMaster queueid={createQueue?._id} />
        </TabPanel> */}
      </Box>
    </>
  );
};
