import React from "react";
import { QueueContext } from "../../contexts";

import { withNavBars } from "./../../HOCs";
import { AddCreateQueue } from "./addCreateQueue";
import axios from "axios";
class CreateQueue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      queue: {},
      queueStates: [],
      queueSenarios: [],
    };
  }

  queueSet = (props) => {
    this.setState({ ...props });
  };

  componentDidMount() {
    this.getUserGroup();
    this.getAssignee();
  }

  getUserGroup = async () => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_CodeableConceptMaster",
        filter: "IDM_CodeableConceptMaster.Type=='PRACTROLE'",
        return_fields:
          "{_id:IDM_CodeableConceptMaster._id, code:IDM_CodeableConceptMaster.coding[0].code,name:IDM_CodeableConceptMaster.coding[0].display,roleid:IDM_CodeableConceptMaster.id}",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        if (res.data.result) {
          sessionStorage.setItem("userGroup", JSON.stringify(res.data.result));
        } else {
          //   alertMessage?.setSnack({
          //     ...alertMessage,
          //     open: true,
          //     severity: AlertProps.severity.error,
          //     msg: `Something Went Worng`,
          //     vertical: AlertProps.vertical.top,
          //     horizontal: AlertProps.horizontal.center,
          //   });
          // alert("Something Went Worng");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  getAssignee = async () => {
    try {
      //  let params = {
      //   db_name: `${localStorage.getItem("ProjectDbname")}`,
      //   entity: "IDM_Person",
      //   filter: "IDM_Person.activestatus==true",
      //   return_fields:
      //     "keep(IDM_Person,'_key','_id','active','username','email','designation','id','roleid')",
      // };

      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_PermissionRoleMapping",
        filter: "IDM_PermissionRoleMapping.activestatus==true",
        return_fields: "IDM_PermissionRoleMapping",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        if (res.data.result) {
          sessionStorage.setItem(
            "userAssignee",
            JSON.stringify(res.data.result)
          );
        } else {
          //   alertMessage?.setSnack({
          //     ...alertMessage,
          //     open: true,
          //     severity: AlertProps.severity.error,
          //     msg: `Something Went Worng`,
          //     vertical: AlertProps.vertical.top,
          //     horizontal: AlertProps.horizontal.center,
          //   });
          // alert("Something Went Worng");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { isconfig } = this.props;
    const queueName = this.state.queue.queuename;
    return (
      <>
        <QueueContext.Provider
          value={{ ...this.state, setQueue: this.queueSet }}
        >
          <AddCreateQueue configure={isconfig} queueName={queueName} />
        </QueueContext.Provider>
      </>
    );
  }
}

export default withNavBars(CreateQueue);
