import * as React from "react";
import { Button } from "@mui/material";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import UploadIcon from "@mui/icons-material/Upload";

let ToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const fileTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon",
];

export function FileUpload(props) {
  const alertMessage = React.useContext(AlertContext);
  const [state, setState] = React.useState({
    file: {},
  });

  const validFileType = (file) => {
    return fileTypes.includes(file.type);
  };

  const handleChange = async (e) => {
    debugger;
    const file = e.target.files[0];
    if (file) {
      let files = new FormData();
      files.append("files", file);
      let base = await ToBase64(file);

      if (validFileType(file)) {
        setState({ ...state, file: base });
        props.changeState(props.label, base);
      } else {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Invalid file format",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        // alert("Invalid file type");
      }
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <input
        onChange={(e) => handleChange(e)}
        type="file"
        value={state?.color}
        id={props.label}
        style={{ display: "none" }}
      />
      <label
        htmlFor={props.label}
        style={{ marginRight: "10px", marginTop: "-2px" }}
      >
        <Button
          variant="outlined"
          size="small"
          component="span"
          color="primary"
          style={{ marginTop: "6px" }}
          startIcon={<UploadIcon />}
        >
          Icon
        </Button>
      </label>
    </div>
  );
}
