import {
  Button,
  Box,
  Divider,
  Paper,
  Stack,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import axios from "axios";
import Tab from "@mui/material/Tab";
import { States } from "./states";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { WorkFlow } from "./workFlow";
import { SenarioDetails } from "./senarioDetails";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { makeStyles } from "@mui/styles";
// import { v4 as uuidv4 } from 'uuid';
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import {
  getRepoid,
  // getRepoIdStateMapping,
  UpdateRepo,
  upsertRepo,
} from "../../utils/apiCall";
// import { SenarioContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    height: "54px",
    borderBottom: " 1px solid #e0e0e0",

    // position: "sticky",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.common,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  content: {
    padding: "8px 16px",
    height: "calc(100% - 54px)",
    overflow: "auto",
    background: theme.palette.background.table,
  },
  paper: {
    // padding: "16px",
    height: "100%",
    border: "1px solid #DCDCDC",
    // overflow: "auto",
    background: theme.palette.background.table,
  },
  Tabs: {
    color: theme.palette.text.primary,
  },
  icon: {
    width: "30px",
    height: "30px",
    // boxShadow: "0 0 2px rgb(184 184 184)",
    borderRadius: "50%",
    padding: "4px",
    color: theme.palette.text.primary,
  },
}));

export const Addscenario = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const alertMessage = React.useContext(AlertContext);
  // const projectinfo = React.useContext(ProjectInfoContext);

  const handleTab = () => {
    switch (value) {
      case "1":
        setValue("2");
        break;
      case "2":
        checkStartAndEndstate();
        // setValue("3");
        break;
      default:
        break;
    }
  };

  const checkStartAndEndstate = () => {
    debugger;
    if (scenarioStatemap.states.length !== 0) {
      let start = scenarioStatemap.states?.filter((l) => l.default === true);
      let end = scenarioStatemap.states?.filter((l) => l.end === true);
      if (start.length > 0 && end.length > 0) {
        return setValue("3");
      } else {
        alertMessage.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Start and end state are empty !",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        return setValue("2");
      }
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "No State Mapped against this Scenario",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return setValue("2");
    }
  };

  const activeStepBack = () => {
    setValue(`${value - "1"}`);
  };

  const [isUpdate, setUpdate] = React.useState(false);

  const [state, setState] = React.useState(
    props?.editData ?? {
      queueid: props.queueid,
      scenarioid: "",
      scenarioname: "",
      bgcolor: "",
      textcolor: "",
      scenariodescription: "",
      scenariocriteria: [],
      // idmrole: [],
      status: true,
      version: "1",
      active: true,
    }
  );

  const [scenarioStatemap, setStateMap] = React.useState({});

  // const readStateData = async () => {
  //   try {
  //     let params = {
  //       db_name: `${localStorage.getItem("ProjectDbname")}`,
  //       entity: "queuescenariostatemapping",
  //       filter: `queuescenariostatemapping.queueid=='${props.editData.queueid}'&&queuescenariostatemapping.scenarioid=='${props.editData.scenarioid}' && queuescenariostatemapping.activestatus==true`,
  //       return_fields: "queuescenariostatemapping",
  //     };

  //     let config = {
  //       method: "post",
  //       url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       data: params,
  //     };
  //     await axios(config)
  //       .then((res) => {
  //         let _data = res.data.result[0];
  //         if (_data?.length === 0) {
  //           setStateMap(res.data.result[0]);
  //         } else {
  //           setStateMap({ ...scenarioStatemap });
  //         }

  //         // console.log(res.data.result);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  React.useEffect(() => {
    if (props.isEdit) {
      readStateMapping();
    }
    // eslint-disable-next-line
  }, [props.isEdit]);

  // const [list, setList] = React.useState([])
  const statemap = (key, value) => {
    setStateMap({ ...scenarioStatemap, [key]: value });
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const updateScenariostate = (key, value) => {
    setState({ ...state, [key]: value });
    // console.log({ ...state, [key]: value })
  };

  const onSaveClicked = () => {
    let isscenarioname = state?.scenarioname.trim().length === 0 ? false : true;
    // isscenarioname = state?.scenarioname.trim().length === 0 ? false : true;
    if (isscenarioname) {
      debugger;
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenarios",
        filter: `queuescenarios.queueid=='${
          props.queueid
        }' &&queuescenarios.activestatus==true && UPPER(queuescenarios.scenarioname) == '${state?.scenarioname.toUpperCase()}'`,
        return_fields: "queuescenarios",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      axios(config).then((res) => {
        let _data = res.data.result;
        if (_data.length === 0) {
          upsertSenarios(state);
        } else {
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Scenario Name Already Exists !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      });
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Scenario Name Can't Be Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const upsertSenarios = async (state) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuescenarios",
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: state,
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config).then((res) => {
        if (res.data.Code === 1111) {
          alertMessage?.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.error,
            msg: `Something Went Wrong(May Be validation mismatching)`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        } else {
          props.tabswitchClose();
          alertMessage.setSnack({
            ...alertMessage,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Scenario added successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          let data = res.data.Result[0].properties.doc;
          const rep = getRepoid(data.queueid);
          rep.then(function (result) {
            //  console.log(result.rep_id);
            if (result?.length > 0) {
              upsertRepo(
                "Task and Document",
                data.scenarioname,
                result[0].rep_id,
                data._id
              );
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrantRepo = async (uniqueid, reptype, repname, queueid, _id) => {
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "IDM_Repository",
        filter: `IDM_Repository.unique_id=='${uniqueid}' && IDM_Repository.activestatus==true`,
        return_fields: "IDM_Repository",
      };
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };

      await axios(config)
        .then((res) => {
          if (res.data.Code === 201) {
            if (res.data.result.length === 0) {
              const rep = getRepoid(queueid);
              rep.then(function (result) {
                debugger;

                //  console.log(result.rep_id);
                if (result?.length > 0) {
                  upsertRepo(reptype, repname, result[0].rep_id, uniqueid);
                }
              });
            } else {
              let data = res.data.result[0];
              const rep = getRepoid(queueid);
              rep.then(function (result) {
                // console.log(result.rep_id);
                if (result?.length > 0) {
                  UpdateRepo(data._key, repname, "", false);
                }
              });
              // UpdateRepo(data._key, repname);
            }
          }

          // console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {}
  };

  const updateSenarios = async (state) => {
    try {
      let params = [
        {
          db_name: `${localStorage.getItem("ProjectDbname")}`,
          entity: "queuescenarios",
          filter: {
            _key: state?._key,
          },
          is_metadata: true,
          metadataId: `${localStorage.getItem("metaId")}`,
          metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
          doc: {
            scenarioname: state?.scenarioname,
            bgcolor: state?.bgcolor,
            icon: state?.icon,
            // idmrole: state?.idmrole,
            textcolor: state?.textcolor,
            scenariodescription: state?.scenariodescription,
            scenariocriteria: state?.scenariocriteria,
          },
        },
      ];
      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          // console.log(res.data);
          if (res?.data?.Code === 201) {
            alertMessage.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Scenario updated successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            let data = res.data.Result[0].properties.doc;
            // props.tabswitchClose();
            getCurrantRepo(
              data._id,
              "Task and Document",
              data.scenarioname,
              data.queueid
              // data._id
            );
          } else if (res?.data?.Code === 1111) {
            alertMessage.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Something Went Wrong!",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateClicked = () => {
    // props?.updateStatedata(props?.id, state)
    let isscenarioname = state?.scenarioname.trim().length === 0 ? false : true;
    if (isscenarioname) {
      insertBothScenarioAndScenarioMapping(state);
    } else {
      alertMessage.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Scenario Name Can't Be Empty",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
    // console.log(scenarioStatemap);
  };

  const insertBothScenarioAndScenarioMapping = async (state) => {
    await updateSenarios(state);
    await upsertStateMapping();
  };

  const upsertStateMapping = async () => {
    try {
      let params;
      if (isUpdate) {
        params = [
          {
            db_name: `${localStorage.getItem("ProjectDbname")}`,
            entity: "queuescenariostatemapping",
            is_metadata: true,
            metadataId: `${localStorage.getItem("metaId")}`,
            metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
            filter: {
              _key: `${scenarioStatemap._key}`,
            },
            doc: {
              states: scenarioStatemap.states,
              workflow: scenarioStatemap.workflow,
              workflowdiagram: scenarioStatemap.workflowdiagram,
            },
          },
        ];
      } else {
        params = [
          {
            db_name: `${localStorage.getItem("ProjectDbname")}`,
            entity: "queuescenariostatemapping",
            is_metadata: true,
            metadataId: `${localStorage.getItem("metaId")}`,
            metadata_dbname: `${process.env.REACT_APP_MetadataDB_Name}`,
            doc: scenarioStatemap,
          },
        ];
      }

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_UPSERT}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then(async (res) => {
          // console.log(res);
          if (res.data.Code === 201) {
            props.tabswitchClose();
            // readStateData();
            readStateMapping();
            // ifupsertStateMappingRepo(scenarioStatemap?.states);
            const rep = await getRepoid(props?.editData?._id);
            rep.then(async (result) => {
              if (result.length > 0) {
                await insertStateMappingRepo(
                  scenarioStatemap?.states,
                  result[0].rep_id
                );
              }
            });
            alertMessage.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.success,
              msg: "Scenario updated successfully",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          } else {
            alertMessage?.setSnack({
              ...alertMessage,
              open: true,
              severity: AlertProps.severity.error,
              msg: `${
                res?.data?.validation_error[0]?.Errormsg ?? res?.data?.Errormsg
              }`,
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("ActionMap", scenarioStatemap.states)
  const readStateMapping = async () => {
    // debugger
    try {
      let params = {
        db_name: `${localStorage.getItem("ProjectDbname")}`,
        entity: "queuescenariostatemapping",
        filter: `queuescenariostatemapping.scenarioid=='${props.editData._id}' && queuescenariostatemapping.active==true && queuescenariostatemapping.activestatus==true`,
        return_fields:
          "MERGE(queuescenariostatemapping,{states:(For doc in queuescenariostatemapping.states return MERGE(doc,{states:document(doc._id).states},{action:(For act in doc.action return MERGE(act,{action:document(act._id).action}))}))})",
      };

      let config = {
        method: "post",
        url: `${process.env.REACT_APP_NODERED_BASE_URL_READ}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: params,
      };
      await axios(config)
        .then((res) => {
          let _data = res.data.result;
          if (_data?.length === 0) {
            setStateMap({
              queueid: props.editData.queueid,
              scenarioid: props.editData._id,
              version: "",
              active: true,
              states: [],
              workflow: [],
              workflowdiagram: [],
            });
            setUpdate(false);
          } else {
            setStateMap(res.data.result[0]);
            setUpdate(true);
          }

          // console.log(res.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const insertStateMappingRepo = async (states, parent_rep) => {
    // if (states?.length > 0) {
    //   states?.map(async (l) => {
    //     // const rep = await getRepoid(props?.editData?._id);
    //     // rep.then(async (result) => {
    //     //   if (result.length > 0) {
    //     const getStateMapping = await getRepoIdStateMapping(parent_rep, l?._id);
    //     getStateMapping.then(async (res) => {
    //       if (res.length === 0) {
    //         let upsertRepoState = await upsertRepo(
    //           "Task and Document",
    //           l.states,
    //           parent_rep,
    //           l._id
    //         );
    //         upsertRepoState.then(async (results) => {
    //           if (results.length > 0) {
    //             if (l.action.length > 0) {
    //               l.action.map(async (j) => {
    //                 const getActionMapping = await getRepoIdStateMapping(
    //                   results[0].rep_id,
    //                   l?._id
    //                 );
    //                 getActionMapping.then(async (Result) => {
    //                   if (Result.length === 0) {
    //                     await upsertRepo(
    //                       "Task and Document",
    //                       l.action,
    //                       results[0].rep_id,
    //                       l._id
    //                     );
    //                   }
    //                 });
    //               });
    //             }
    //           }
    //         });
    //       }
    //     });
    //     // }
    //     // });
    //   });
    // }
  };

  return (
    // <SenarioContext.Provider
    //   value={{ ...scenarioStatemap, setScenarios: statemap }}
    // >
    <Paper className={classes.paper} elevation={0}>
      <TabContext value={value}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.Header}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={props.onCancelClicked}>
              <Tooltip title={"Back to Scenario"} arrow>
                <ArrowBackIcon className={classes.icon} />
              </Tooltip>
            </IconButton>
            <Typography variant="subtitle1">
              {props?.isEdit ? props?.editData?.scenarioname : "Scenario"}
            </Typography>
          </Box>
          <Box>
            <TabList
              // onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: {
                  height: "3px",
                  borderRadius: "5px 5px 0px 0px",
                },
              }}
              // sx={{ cursor: "default" }}
            >
              <Tab
                disableRipple
                value="1"
                label="Scenario Details"
                className={classes.Tabs}
                sx={{ cursor: "default" }}
              />
              <Tab
                disableRipple
                value="2"
                disabled={props.isNew ?? false}
                label="States"
                className={classes.Tabs}
                sx={{ cursor: "default" }}
              />
              <Tab
                disableRipple
                value="3"
                disabled={props.isNew ?? false}
                label="Workflow"
                className={classes.Tabs}
                sx={{ cursor: "default" }}
              />
            </TabList>
          </Box>
          <div style={{ display: "flex", marginRight: "16px" }}>
            {props?.isEdit && value !== "1" && (
              <Button
                id={"Back Button"}
                onClick={() => activeStepBack()}
                variant="outlined"
                // className={classes.Button}
              >
                {props?.isEdit ? "Back" : "Cancel"}
              </Button>
            )}

            {!props?.isEdit ? (
              <Button
                id={"Save Button"}
                onClick={() => onSaveClicked()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Save"}
              </Button>
            ) : value === "3" ? (
              <Button
                id={"Save Button"}
                onClick={() => onUpdateClicked()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Save"}
              </Button>
            ) : (
              <Button
                id={"Next Button"}
                onClick={() => handleTab()}
                variant="contained"
                sx={{ ml: "16px" }}
                className={classes.Button}
              >
                {"Next"}
              </Button>
            )}
          </div>
        </Stack>
        <Divider></Divider>
        <TabPanel className={classes.content} value="1">
          <SenarioDetails
            isNew={props?.isNew}
            isEdit={props?.isEdit}
            createQueue={props?.createQueue}
            state={state}
            updateScenarios={updateScenariostate}
            scenariosList={props?.scenariosList}
          />
        </TabPanel>
        <TabPanel className={classes.content} value="2">
          <States
            qid={props?.editData?.queueid}
            isEdit={props?.isEdit}
            state={scenarioStatemap?.states}
            updateScenarios={statemap}
            scenariosid={props?.editData?._id}
          ></States>
        </TabPanel>
        <TabPanel
          // style={{ height: "calc(100% - 52px)", overflowY: "auto" }}
          className={classes.content}
          value="3"
        >
          <WorkFlow
            workflowDiagram={scenarioStatemap?.workflowdiagram}
            createQueue={props?.createQueue}
            ScenariosList={props?.scenariosList}
            stateList={scenarioStatemap?.states}
            scenarioStatemap={scenarioStatemap}
            updateScenarios={statemap}
            currentScenarios={state}
          />
        </TabPanel>
      </TabContext>
    </Paper>

    // </SenarioContext.Provider>
  );
};
