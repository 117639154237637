import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.common,
    width: "100%",
    height: "100%",
  },
  content: {
    // padding: theme.spacing(1),
    height: "100%",
    width: "100%",
  },
}));

export const TabPanel = (props) => {
  const classes = useStyle();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      // style={{ width: "100%", height: "100%" }}
      className={classes.root}
    >
      {value === index && (
        <Box className={classes.content} {...props}>
          {props.children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
