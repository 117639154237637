import {
  Button,
  //   Chip,
  Divider,
  Modal,
  Paper,
  //   TextField,
  Typography,
  IconButton,
  Grid,
  TextField,
  Tooltip,
  //   LinearProgress,
  //   Grid,
  // Autocomplete,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  //   DrawerProps,
  AlertProps,
  performData,
} from "../../utils";
import { styled } from "@mui/material/styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DragIndicatorOutlinedIcon from "@mui/icons-material/DragIndicatorOutlined";
// import { Message } from "../../utils/messages";
import {
  DialogContext,
  //   DrawerContext,
  //   QueueContext,
  AlertContext,
} from "../../contexts";
// import { AddEditState } from './addEditState';
// import { DrawerAddAction } from "./statesAndAction.addAction";
// import axios from "axios";
import { CreateRule } from "td-rule-builder";
import { v4 as uuidv4 } from "uuid";
import { ColorTextBox, FileUpload, IsRequired } from "../../components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles((theme) => ({
  Header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    background: theme.palette.background.table,
    borderBottom: " 1px solid #e0e0e0",
  },
  Header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "8px 16px",
    height: "52px",
    background: theme.palette.background.tableHeader,
  },
  wrapperdiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "16px",
  },
  actionList: {
    padding: "8px 16px",
    height: "calc(100% - 114px)",
    overflowY: "auto",
  },
  actionListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  wrapperdiv2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // margin: "8px 16px",
  },
  wrapperdiv3: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "8px 16px 0 16px",
  },
  content: {
    height: "calc(100% - 55px)",
    padding: "16px",
    overflowY: "scroll",
    background: theme.palette.background.table,
  },
  style: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "80%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    background: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  style1: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    // height: "70%",
    // bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    overflowY: "scroll",
    background: theme.palette.background.table,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  papers: {
    height: "100%",
    border: "1px solid #DCDCDC",
    background: theme.palette.background.table,
  },
  Button: {
    background: theme.palette.button.background,
    color: theme.palette.button.text,
    "&:hover": {
      backgroundColor: theme.palette.button.hover,
    },
  },
  Table: {
    background: theme.palette.background.table,
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  TableHeader: {
    background: theme.palette.background.tableHeader,
  },
  TableBody: {
    background: theme.palette.background.table,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.action.hover,
    lineHeight: "0rem",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.white,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const QueueGroup = (props) => {
  const { createQueue } = props;
  const classes = useStyles();
  const dialogContext = React.useContext(DialogContext);
  const alertMessage = React.useContext(AlertContext);
  const [change, setChange] = React.useState(false);
  const [group, setGroup] = React.useState({
    groupid: "",
    groupname: "",
    ruleid: [],
    priority: "",
    bgcolor: "",
    textcolor: "",
    groupicon: "",
    hovericon: "",
  });

  const [openGroup, setOpenGroup] = React.useState({
    open: false,
    edit: false,
    editData: false,
  });

  const [queueGrp, setQueueGrp] = React.useState(
    createQueue?.groupdetails ?? []
  );
  const [actions, setAction] = React.useState({
    isEdit: false,
    open: false,
  });

  const [inputSchemaList, setState] = React.useState([]);

  React.useEffect(() => {
    if (props?.isconfig === false) {
      setState(JSON.parse(createQueue?.inputschema[0]?.inputschema));
    }
  }, [props?.isconfig, createQueue]);

  const onHandleChange = (key, value) => {
    if (key === "groupname") {
      setGroup({ ...group, [key]: value });
      setChange(true);
    } else {
      setGroup({ ...group, [key]: value });
    }
  };

  const onConfigClicked = (data) => {
    setAction({
      ...actions,
      open: true,
      isEdit: false,
      editData: data,
    });
  };

  const handleClose = () => {
    setAction({
      ...actions,
      open: false,
      isEdit: false,
      // editData: "",
    });
  };

  const handleCloseGroup = () => {
    setOpenGroup({
      ...openGroup,
      open: false,
      isEdit: false,
      editData: "",
    });
  };

  const onAddGroupClicked = () => {
    setOpenGroup({
      ...openGroup,
      open: true,
      isEdit: false,
      editData: {},
    });
    setGroup({
      groupid: "",
      groupname: "",
      ruleid: [],
      priority: "",
      bgcolor: "",
      textcolor: "",
      groupicon: "",
      hovericon: "",
    });
  };

  const onEditQueueGrp = (l) => {
    setOpenGroup({
      ...openGroup,
      open: true,
      isEdit: true,
      editData: l,
    });
    setGroup(l);
  };

  const onDeleteQueueGrp = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete the state?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => onDeletedClicked(data),
    });
  };

  const onDeletedClicked = (data) => {
    let list = queueGrp.filter((l) => l.groupid !== data.groupid);
    props.updateState("groupdetails", list);
    setQueueGrp(list);
    alertMessage?.setSnack({
      ...alertMessage,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Group Deleted Successfully",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
  };

  const onSaveClicked = (data) => {
    // console.log(data)
    debugger;
    if (!data) {
      //   alert("unable to save data");
      setAction({
        ...actions,
        open: false,
        isEdit: false,
        // editData: "",
      });
    } else {
      let rule_id = [data?.Result[0]?.properties?.doc?._id];
      let perform =
        data?.Result[0]?.properties?.doc?.uiMeta?.perform[0]?.filter(
          (l) =>
            l?.actions?.label === "priority" &&
            l?.actions?.aliasName === "priority"
        );
      let priority = perform[0]?.states ?? "";
      let list = queueGrp.map((l, i) =>
        l.groupid === actions.editData.groupid
          ? {
              ...l,
              ruleid: rule_id,
              priority: priority,
            }
          : l
      );
      props.updateState("groupdetails", list);
      setQueueGrp(list);
      handleClose();
      /* if (data?.Result[0]?.properties?.type === "update") {
        debugger;
        let ruleid = [data?.Result[0]?.properties?.doc?._id];
        let RuleName = data?.Result[0]?.properties?.doc?.ruleName;
        let perform =
          data?.Result[0]?.properties?.doc?.uiMeta?.perform[0]?.filter(
            (l) =>
              l?.actions?.label === "priority" &&
              l?.actions?.aliasName === "priority"
          );
        let priority = perform[0]?.states ?? "";

        let list = queueGrp.map((l) =>
          l.ruleid[0] === ruleid[0]
            ? {
                ...l,
                groupname: RuleName,
                priority: priority,
              }
            : l
        );
        props.updateState("groupdetails", list);
        setQueueGrp(list);
        handleClose();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Grouped Updated Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } else {
        let ruleid = [data?.Result[0]?.properties?.doc?._id];
        let RuleName = data?.Result[0]?.properties?.doc?.ruleName;
        let perform =
          data?.Result[0]?.properties?.doc?.uiMeta?.perform[0]?.filter(
            (l) =>
              l?.actions?.label === "priority" &&
              l?.actions?.aliasName === "priority"
          );
        let priority = perform[0]?.states ?? "";

        props.updateState("groupdetails", [
          ...queueGrp,
          {
            groupid: uuidv4(),
            groupname: RuleName,
            ruleid: ruleid,
            priority: priority,
          },
        ]);
        setQueueGrp([
          ...queueGrp,
          {
            groupid: uuidv4(),
            groupname: RuleName,
            ruleid: ruleid,
            priority: priority,
          },
        ]);
        handleClose();
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Group Added Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      } */
    }
  };

  const onSaveGroup = () => {
    debugger;
    let isGrpName = group?.groupname?.trim().length === 0 ? false : true;

    if (isGrpName) {
      let filter = queueGrp.filter((l, i) => l.groupname === group.groupname);

      if (filter.length === 0) {
        setQueueGrp([
          ...queueGrp,
          {
            groupid: uuidv4(),
            groupname: group.groupname,
            ruleid: group.ruleid,
            priority: group.priority,
            bgcolor: group.bgcolor,
            textcolor: group.textcolor,
            groupicon: group.groupicon,
            hovericon: group.hovericon,
          },
        ]);
        props.updateState("groupdetails", [
          ...queueGrp,
          {
            groupid: uuidv4(),
            groupname: group.groupname,
            ruleid: group.ruleid,
            priority: group.priority,
            bgcolor: group.bgcolor,
            textcolor: group.textcolor,
            groupicon: group.groupicon,
            hovericon: group.hovericon,
          },
        ]);

        handleCloseGroup();
      } else {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Group Name already exist!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else {
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required fields are empty!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const onUpdateGroup = () => {
    debugger;
    let isGrpName = group?.groupname?.trim().length === 0 ? false : true;

    if (isGrpName) {
      let filter = change
        ? queueGrp.filter((l, i) => l.groupname === group.groupname)
        : [];
      if (filter.length === 0) {
        let list = queueGrp.map((l, i) =>
          l.groupid === group.groupid
            ? {
                ...l,
                groupname: group.groupname,
                priority: group.priority,
                bgcolor: group.bgcolor,
                textcolor: group.textcolor,
                groupicon: group.groupicon,
                hovericon: group.hovericon,
              }
            : l
        );
        props.updateState("groupdetails", list);
        setQueueGrp(list);
        handleCloseGroup();
        setChange(false);
      } else {
        alertMessage?.setSnack({
          ...alertMessage,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Group already exist!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }
    } else {
      alertMessage?.setSnack({
        ...alertMessage,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Required fields are empty!",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  return (
    <>
      <Paper className={classes.papers} elevation={0}>
        <Box className={classes.Header}>
          <Typography variant="body1">Grouping</Typography>
          <Button
            id={"Add State Button"}
            onClick={() => onAddGroupClicked()}
            variant="text"
            style={{ color: "#3495FF" }}
          >
            {"+ Add Group"}
          </Button>
        </Box>
        <Divider />
        <div className={classes.content}>
          {queueGrp.length > 0 ? (
            <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
              <Table
                size="small"
                aria-label="customized table"
                className={classes.Table}
              >
                <TableHead className={classes.TableHeader}>
                  <TableRow>
                    <StyledTableCell width={15} align="left">
                      <pre> </pre>
                    </StyledTableCell>
                    <StyledTableCell width={15} align="left">
                      S.No
                    </StyledTableCell>
                    <StyledTableCell align="left">Group Name</StyledTableCell>
                    <StyledTableCell width={150} align="center">
                      Rule
                    </StyledTableCell>
                    <StyledTableCell width={150} align="center">
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody className={classes.TableBody}>
                  {queueGrp?.map((list, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell width={15} align="left">
                        <IconButton disableRipple={true}>
                          <DragIndicatorOutlinedIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {list?.groupname}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <div className={classes.wrapperdiv2}>
                          <Button
                            id={"Configure Button"}
                            variant="contained"
                            onClick={() => onConfigClicked(list)}
                            //onClick={onRuleModelOpen}
                            style={{
                              backgroundColor: "#66AFFF",
                              margin: "0 16px",
                            }}
                          >
                            {"Configure"}
                          </Button>
                          {list?.ruleid?.length > 0 ? (
                            <Tooltip title="Scenario Criteria Completed">
                              <CheckCircleIcon color="success" />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Scenario Criteria Not Completed">
                              <ErrorIcon sx={{ color: "#ffcc00" }} />
                            </Tooltip>
                          )}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <IconButton
                          id={"Edit Icon"}
                          disableRipple={true}
                          onClick={() => onEditQueueGrp(list)}
                        >
                          <EditOutlinedIcon color="primary" />
                        </IconButton>
                        <IconButton
                          id={"Delete Icon"}
                          onClick={() => onDeleteQueueGrp(list)}
                          disableRipple={true}
                        >
                          <DeleteOutlineOutlinedIcon color="error" />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography align="center" variant="subtitle2" color="GrayText">
                {"No Grouping Available, Add some Group"}
              </Typography>
            </div>
          )}
        </div>
        {/* Model */}
        <Modal
          open={actions.open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.style}>
            {/* <Box className={classes.Header}>
              <Typography variant="body1">
                {"+ Add Group"}
              </Typography>
            </Box> */}
            <Divider />
            {props?.isconfig === false ? (
              <CreateRule
                inputSchema={inputSchemaList}
                editId={actions?.editData?.ruleid[0] ?? ""}
                handleClose={(data) => {
                  onSaveClicked(data);
                }}
                open={true}
                performvalueData={{
                  // stateList: stateList,
                  // scenarioList: scenarioList,
                  queueList: JSON.parse(sessionStorage.getItem("queueList")),
                  // queueList={[props?.createQueue]}
                  // queueList: queueList,
                  groupNameList: queueGrp ?? [],
                  userRole: JSON.parse(sessionStorage.getItem("userAssignee")),
                  userGroup: JSON.parse(sessionStorage.getItem("userGroup")),
                }}
                performKeyData={performData}
                dbName={`${process.env.REACT_APP_DB}`}
              />
            ) : (
              ""
            )}
          </Box>
          {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
        </Modal>
        <Modal
          open={openGroup.open}
          onClose={handleCloseGroup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.style1}>
            <Box className={classes.Header}>
              <Typography variant="body1">
                {openGroup.isEdit ? "Update Group" : "Add Group"}
              </Typography>
            </Box>
            <Divider />
            <div style={{ padding: "16px" }}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Group Name"}
                    <IsRequired />
                  </Typography>
                  <TextField
                    onChange={(e) =>
                      onHandleChange("groupname", e.target.value)
                    }
                    placeholder="Enter Group Name"
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="states"
                    // error={err}
                    // onKeyPress={(e) => KeyPress(e)}
                    // helperText={err === true ? Message.requiredMessage : ""}
                    value={group?.groupname}
                  />
                </Grid>
                <Grid item xs={12} lg={6} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Background Color"}
                  </Typography>
                  <ColorTextBox
                    value={group?.bgcolor}
                    onHandleChange={(data) => onHandleChange("bgcolor", data)}
                  />
                  {/* <Box
                    sx={{
                      width: 600,
                      maxWidth: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="color"
                      value={group?.bgcolor}
                      style={{
                        height: "30px",
                        width: "30px",
                        border: "none",
                        borderRadius: "3px",
                        padding: "2px",
                        marginRight: "4px",
                      }}
                      onChange={(e) =>
                        onHandleChange("bgcolor", e.target.value)
                      }
                    />
                    <TextField
                      value={group?.bgcolor}
                      fullWidth
                      size="small"
                      id="fullWidth"
                      autoComplete="off"
                      inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                </Grid>
                <Grid item xs={12} lg={6} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Text Color"}
                  </Typography>
                  <ColorTextBox
                    value={group?.textcolor}
                    onHandleChange={(data) => onHandleChange("textcolor", data)}
                  />
                  {/* <Box
                    sx={{
                      width: 600,
                      maxWidth: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="color"
                      value={group?.textcolor}
                      style={{
                        height: "30px",
                        width: "30px",
                        border: "none",
                        borderRadius: "3px",
                        padding: "2px",
                        marginRight: "4px",
                      }}
                      onChange={(e) =>
                        onHandleChange("textcolor", e.target.value)
                      }
                    />
                    <TextField
                      value={group?.textcolor}
                      fullWidth
                      size="small"
                      id="fullWidth"
                      autoComplete="off"
                      inputProps={{ readOnly: true }}
                    />
                  </Box> */}
                </Grid>
                <Grid item xs={6} lg={6} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Icon"}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FileUpload
                      changeState={onHandleChange}
                      label="groupicon"
                    />
                    {group?.groupicon && (
                      <img
                        width="20"
                        height="20"
                        style={{ marginLeft: "8px", marginTop: "2px" }}
                        src={group?.groupicon}
                        alt={"Scenario Icon"}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={6} lg={6} sx={{ py: 1 }}>
                  <Typography variant="body2" gutterBottom>
                    {"Hover Icon"}
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FileUpload
                      changeState={onHandleChange}
                      label="hovericon"
                    />
                    {group?.hovericon && (
                      <img
                        width="20"
                        height="20"
                        style={{ marginLeft: "8px", marginTop: "2px" }}
                        src={group?.hovericon}
                        alt={"Scenario Icon"}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
            <Divider />
            <div className={classes.wrapperdiv}>
              <Button
                id={"Cancel Button"}
                onClick={() => handleCloseGroup()}
                variant="outlined"
              >
                {"Cancel"}
              </Button>
              {openGroup.isEdit ? (
                <Button
                  id={"Update Button"}
                  onClick={() => onUpdateGroup()}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Update"}
                </Button>
              ) : (
                <Button
                  id={"Add Button"}
                  onClick={() => onSaveGroup()}
                  variant="contained"
                  sx={{ ml: "16px" }}
                  className={classes.Button}
                >
                  {"Add"}
                </Button>
              )}
            </div>
          </Box>
          {/* <AddEditState handleClose={handleClose} updateStatedata={updateStatedata} /> */}
        </Modal>
      </Paper>
    </>
  );
};
