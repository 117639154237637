/**
 * Object with role as key and value, which is used for
 * comparison of role in different place.
 */
export const UserRoles = {
  role: "role",
};

/**
 * Object which has the proper name of all the role
 * used in the application.
 */
export let UserRolesName = {
  role: "Role",
};

/**
 * Object which has the different themes used in
 * the application.
 */
export let Themes = {
  default: "default",
  dark: "dark",
  clmmdark: "clmmdark",
  clmmdarkgreen: "clmmdarkgreen",
  clmmdefaultgreen: "clmmdefaultGreen",
  clmmdefault: "clmmdefault",
  clmmdefaultPurple: "clmmdefaultPurple",
  clmmdarkPurple: "clmmdarkPurple",
};

/**
 * Object which has the different props for the Alert Component (/src/component/alert)
 * which is used via AlertContext (/src/contexts) and provided at /src/App.alert.js.
 */
export let AlertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

/**
 * Object which has the different props for the Drawer Component (/src/App.drawer.js)
 * which is used via DrawerContext (/src/contexts) and provided at /src/App.drawer.js.
 */
export const DrawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};

/**
 * Object has the key and value pair of all the keys which
 * are used to store some values in the local storage.
 */
export let LocalStorageKeys = {
  //   projectName: "",
  metaDataId: "metaDataId",
  authToken: "authtoken",
  clientName: "clientname",
  projectName: "projectName",
  ProjectDbname: "ProjectDbname",
  ProjectId: "ProjectId",
  clientid: "clientid",
  version: "version",
};

/**
 * Object has the key and value pair of all the HTTP method
 * used for an network call.
 */
export let NetWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const performData = [
  {
    label: "Set as Queue",
    value: 1,
    aliasName: "queuename",
    labelName: "queuename",
    listName: "queueList",
  },
  {
    label: "Set as Scenario",
    value: 2,
    aliasName: "scenarioname",
    labelName: "scenarioname",
    listName: "scenarioList",
  },
  {
    label: "Set as State",
    value: 3,
    aliasName: "states",
    labelName: "states",
    listName: "stateList",
  },
  // {
  //   label: "Set as Group Name",
  //   value: 4,
  //   aliasName: "GroupName",
  //   labelName: "GroupName",
  //   listName: "groupNameList",
  // },
  {
    label: "Set as Action",
    value: 5,
    aliasName: "action",
  },
  {
    label: "Set as User Action",
    value: 5,
    aliasName: "userAction",
  },
  {
    label: "Set Assignee",
    value: 4,
    aliasName: "userRole",
    children: [
      {
        label: "Set single assignee",
        aliasName: "singleasignee",
        labelName: "email",
        listName: "userRole",
      },

      {
        label: "Set to Group",
        aliasName: "group",
        children: [
          {
            label: "Based on Availablity",
            aliasName: "singleasignee",
            labelName: "name",
            listName: "userGroup",
          },
        ],
      },
    ],
  },
  {
    label: "Set as Group Name",
    value: 6,
    aliasName: "groupname",
    labelName: "groupname",
    listName: "groupNameList",
  },
  {
    label: "Set as priority",
    value: 7,
    aliasName: "priority",
    labelName: "priority",
    listName: "priorityList",
  },
];
