import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import { MeetingRoom } from '@mui/icons-material';
import { LocalStorageKeys } from "../../../utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
// import { SideNavBar } from '..';
import Popover from "@mui/material/Popover";
// import Button from '@mui/material/Button';
import LogoutIcon from "@mui/icons-material/Logout";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.appBar.main,
  },
  title: {
    display: "block",
  },
  titleContainer: {
    marginLeft: theme.spacing(2),
  },
  menuIcon: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfilecard: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center",
  },
  profilePic: {
    marginLeft: "8px",
  },
}));

export const TopNavBar = (props) => {
  const classes = useStyles();

  /* const [state, setState] = React.useState({
    openSideNavBar: false,
  }); */

  // const handleLogout = () => {

  // }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem(LocalStorageKeys.authToken);
    window.location.replace(process.env.REACT_APP_QDM_ADMIN_ENDPOINT);
    // window.location.replace("/");
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /* const toogleSideNavBar = () => {
    setState({
      ...state,
      openSideNavBar: !state.openSideNavBar,
    });
  }; */

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          {/* <IconButton
            className={classes.menuIcon}
            onClick={toogleSideNavBar}
            size="large"
          >
            <MenuIcon htmlColor="white" />
          </IconButton> */}

          <div className={classes.titleContainer}>
            <Typography className={classes.title} variant="body1" noWrap>
              {"T&Ds Workflow"}
            </Typography>
            <Typography variant="caption">
              {`v ${localStorage.getItem(LocalStorageKeys.version)}`}
            </Typography>
          </div>

          <div className={classes.grow} />

          <div className={classes.userProfilecard}>
            <Typography className={classes.title} variant="body1" noWrap>
              {/* userName */}
              Admin
            </Typography>
            <Avatar className={classes.profilePic} variant="rounded">
              A
            </Avatar>
          </div>
          <IconButton onClick={handleClick}>
            <ExpandMoreIcon style={{ color: "white" }} />
          </IconButton>

          {/*  <Drawer
                        open={state.openSideNavBar}
                        variant={"temporary"}
                        anchor="left"
                        onClose={toogleSideNavBar}>
                        <div style={{ width: 240 }}>
                            <SideNavBar isMobile={true} />
                        </div>
                    </Drawer> */}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem>
            <ListItemButton onClick={() => handleLogout()}>
              <ListItemIcon sx={{ width: "30px" }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
